<div class="root-container">
    <div class="input-container"
         [style.margin-top]="marginTop + 'px'"
         [style.margin-bottom]="marginBottom + 'px'">

        <!-- Label With Error -->
        <label
                class="label lp position-relative mb-1"
                *ngIf="showLabel && labelText"
                [ngClass]="{ 'required-text': showErrorMessage }"
        >{{
                showErrorMessage ? errorMessage : labelText
            }}<span class="mandatory" *ngIf="showLabel && isMandatory"> *</span></label
        >

        <div class="position-relative">
            <!-- HTML Editor Input Field -->
            <ckeditor [id]="id" [editor]="editorInstance" [(ngModel)]="value" (ngModelChange)="inputValueHandler($event)"
                      [disabled]="disabled" [config]="editorConfig" (ready)="onEditorReady($event)"></ckeditor>

            <!-- Input Field Info Message Text -->
            <span *ngIf="inputInfoMessage" class="info-message">{{ inputInfoMessage }}</span>
        </div>
    </div>


    <div class="upload-root-container">
        <!-- Logo Container -->
        <div class="upload-button-container">

            <!-- Profile Picture Upload Icon Container -->
            <label class="upload-image-input-container">
                <input
                        type="file"
                        #fileInput
                        id="uploadImage"
                        name="file"
                        class="upload-image-input"
                        (change)="uploadMedia($event, 'image')"
                        (click)="$event.stopPropagation()"
                        accept=".jpg, .jpeg, .png"
                />
                <!-- Upload Image Button -->
                <app-button
                        width="200"
                        label="Upload Image"
                        height="36"
                ></app-button>
            </label>
        </div>
    </div>
</div>
