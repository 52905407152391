import { API_TIMEOUT, Constants } from './Constants';
import { NetworkResponse, NetworkResponseType } from './NetworkResponse';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ApiRequestConfigService } from './api.configuration.service';
import { AppConstants } from 'src/app/constants/AppConstants';
import { BaseModel } from 'src/app/models/BaseModel';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import queryString from 'query-string';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  /**
   * Makes a request to the API using the provided configuration
   * @param configs - Configuration object for the API request
   * @returns A NetworkResponse object containing the response data or error message
   */
  async apiRequest<T extends BaseModel> (
    configs: ApiRequestConfigService,
  ): Promise<NetworkResponse<T>> {
    let url: string = configs.isVecLogicBaseUrl
      ? environment.vecLogicBaseUrl + configs.url
      : environment.baseUrl + configs.url;

    // Check if path parameter is provided and append it to the URL
    if (configs.pathParam) {
      url += `/${ configs.pathParam }`;
    }

    // Check if query parameter is provided and append it to the URL
    if (configs.queryParams) {
      url += `?${ queryString.stringify(configs.queryParams) }`;
    }

    // Axios request configuration object
    const config: AxiosRequestConfig = {
      method: configs.method, // HTTP method
      url: url, // API endpoint URL
      headers: {
        Accept: Constants.JSON,
        'Content-Type': configs.contentType
          ? configs.contentType
          : Constants.JSON,
        Authorization: `Bearer ${ configs.token ?? localStorage.getItem(
          AppConstants.LocalStorageKeys.AUTH_TOKEN,
        ) }`,
      },
      timeout: API_TIMEOUT,
      ...(configs.data && { data: configs.data }), // Data to be sent in the request body
    };

    try {
      // Send the request using axios and get the response
      const response: AxiosResponse<T> = await axios.request<T>(config);

      // Status codes in the 2XX range
      return {
        responseType: NetworkResponseType.SUCCESS,
        code: response.status,
        response: response.data,
      } as NetworkResponse<T>;
    } catch (error: any) {
      // Error is not occurred by API Request
      if (!axios.isAxiosError(error)) {
        return {
          responseType: NetworkResponseType.UNKNOWN_ERROR,
          code: -1,
          error: error,
          response: {
            success: false,
            message: error.message,
          } as T,
        } as NetworkResponse<T>;
      }

      const axiosError = error as AxiosError<T>;

      // The request was made and the server responded with a status code
      if (axiosError.response) {
        // TODO - Remove After Testing
        console.log('Network Service: AxiosError.Response:');
        console.log(axiosError.response);

        let dataModel: T;

        const { data } = axiosError.response;

        // Reason: Some Times 'axiosError.response.data' return String even it is T type
        if (typeof data === 'object') {
          dataModel = {
            ...axiosError.response.data,
          };
        } else {
          // @ts-ignore: Reason: Some Times 'axiosError.response.data' return String even it is T type
          dataModel = {
            success: false,
            message: data,
          };
        }

        if (dataModel.message === null || dataModel.message === '') {
          dataModel.message = axiosError.response.statusText;
        }

        return {
          responseType: NetworkResponseType.API_ERROR,
          code: axiosError.response.status,
          response: dataModel,
          error: axiosError,
        } as NetworkResponse<T>;
      }

      // The request was made, but no response was received
      else if (axiosError.request) {
        // TODO - Remove After Testing
        console.log('Network Service: AxiosError.Request:');
        console.log(axiosError.request);

        let dataModel: T;

        const { data } = axiosError.request;

        // Reason: Some Times 'axiosError.request.data' may return String even it is T type
        if (typeof data === 'object') {
          dataModel = {
            ...axiosError.request.data,
          };
        } else {
          // @ts-ignore: Reason: Some Times 'request.response.data' may return String even it is T type
          dataModel = {
            success: false,
            message: data?.toString() ?? '',
          };
        }

        if (dataModel.message === null || dataModel.message === '') {
          dataModel.message = axiosError.request.statusText;
        }

        if (dataModel.message === null || dataModel.message === '') {
          dataModel.message = axiosError.request.message;
        }

        if (dataModel.message === null || dataModel.message === '') {
          dataModel.message = axiosError.request._response;
        }

        return {
          responseType: NetworkResponseType.UNKNOWN_ERROR,
          code: axiosError.request.status ?? axiosError.request.statusCode,
          response: dataModel,
          error: axiosError,
        } as NetworkResponse<T>;
      } else {
        // TODO - Remove After Testing
        console.log(`Network Service: Else Error: ${ axiosError }`);

        // Something happened in setting up the request that triggered an error
        return {
          responseType: NetworkResponseType.UNKNOWN_ERROR,
          code: axiosError.status,
          response: {
            success: false,
            message: axiosError.message,
          } as T,
          error: error,
        } as NetworkResponse<T>;
      }
    }
  }
}
