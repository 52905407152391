import { AdditionalPromptsModel } from '../../../models/AdditionalPromptsModel';
import { BaseModel } from 'src/app/models/BaseModel';
import { HttpMethod } from '../../network/HttpMethod';
import { Injectable } from '@angular/core';
import { NetworkResponse } from '../../network/NetworkResponse';
import { NetworkService } from '../../network/network.service';

@Injectable({
  providedIn: 'root',
})
export class AdditionalPromptsService {
  constructor (private networkService: NetworkService) {
    // Empty Constructor
  }

  // START - PROMPTS APIs
  /**
     * Request to Get Additional Prompts List
     *
     * @param {string} _id - Category ID
     * @return Promise with Response
     */
  getAdditionalPrompt (
    _id: string,
  ): Promise<NetworkResponse<AdditionalPromptsModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.GET,
      url: `api/v2/prompts/get/${ _id }`,
      isVecLogicBaseUrl: true,
    });
  }

  /**
     * Request to Create New Shop
     *
     * @param {object} body - Request Body
     * @return Promise with Response
     */
  createAdditionalPrompt (body: object): Promise<NetworkResponse<BaseModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.POST,
      url: 'api/v2/prompts/create',
      data: body,
      isVecLogicBaseUrl: true,
    });
  }

  /**
     * Request to Update Shop
     *
     * @param {object} body - Request Body
     * @param {string} _id - Category ID
     * @return Promise with Response
     */
  updateAdditionalPrompt (body: object, _id: string): Promise<NetworkResponse<BaseModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.PUT,
      url: `api/v2/prompts/update/${ _id }`,
      data: body,
      isVecLogicBaseUrl: true,
    });
  }

  /**
     * Request to Delete Additional Prompt
     *
     * @param {string} _id - Category ID
     * @return Promise with Response
     */
  deleteAdditionalPrompt (_id: string): Promise<NetworkResponse<BaseModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.DELETE,
      url: `api/v2/prompts/delete/${ _id }`,
      isVecLogicBaseUrl: true,
    });
  }

  // END - PROMPTS APIs
}
