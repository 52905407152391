<div class="input-container"
     [style.margin-top]="marginTop + 'px'"
     [style.margin-bottom]="marginBottom + 'px'">

    <!-- Label With Error -->
    <label
            class="label lp position-relative mb-1"
            *ngIf="showLabel && labelText"
            [ngClass]="{ 'required-text': showErrorMessage }"
    >{{
            showErrorMessage ? errorMessage : labelText
        }}<span class="mandatory" *ngIf="showLabel && isMandatory"> *</span></label
    >

    <div class="position-relative">
        <!-- Primary Input Field -->
        <input
                (change)="inputValueHandler($event)"
                (keydown)="sharedService.checkInput($event, type, maxLength)"
                (keyup)="inputKeyEventHandler($event)"
                *ngIf="
        type !== 'textarea' &&
        type !== 'file' &&
        type !== 'date' &&
        type !== 'address' &&
        type !== 'color-picker' &&
        type !== 'toggle' &&
        type !== 'time'
      "
                [disabled]="disabled"
                [id]="id"
                [ngClass]="{ 'disabled-input-box': disabled === true, 'padding-right': showPasswordIcon === true, 'search-field': type === 'search', 'required': showErrorMessage}"
                [placeholder]="placeholder"
                [readonly]="readonly"
                [required]="required"
                [style.height]="height + 'px'"
                [type]="type"
                [value]="value ?? ''"
                class="input-box"
                name="{{ name }}"
        />

        <!-- Address Input Field Google Places -->
        <input
            #placesRef="ngx-places"
            (change)="handleAddressChange(null, $event)"
            (onAddressChange)="handleAddressChange($event)"
            *ngIf="type === 'address'"
            [disabled]="disabled"
            [id]="id"
            [ngClass]="{'disabled-input-box': disabled === true,'required': showErrorMessage}"
            [placeholder]="placeholder"
            [readonly]="readonly"
            [required]="required"
            [style.height]="height + 'px'"
            [type]="type"
            [value]="value ?? ''"
            class="input-box"
            name="{{ name }}"
            ngx-google-places-autocomplete
        />

        <!-- Date Type Input Field -->
        <input
                (change)="inputValueHandler($event)"
                (keyup)="inputKeyEventHandler($event)"
                *ngIf="type === 'date'"
                [disabled]="disabled"
                [id]="id"
                [ngClass]="{ 'disabled-input-box': disabled === true, 'required': showErrorMessage }"
                [placeholder]="placeholder"
                [readonly]="true"
                [required]="required"
                [style.height]="height + 'px'"
                [type]="'text'"
                [value]="value"
                class="input-box cursor-pointer"
                name="{{ 'date' }}"
        />

        <!-- Date Icon Container -->
        <span
                (click)="onCalendarClick()"
                *ngIf="type === 'date'"
                class="end-icon-container position-absolute"
        >
      <!-- Date Icon -->
      <img
              class="end-icon cursor-pointer"
              src="/assets/icons/ic-calendar-light.svg"
      />
    </span>

        <!-- Date Picker Calendar -->
        <mat-form-field *ngIf="type === 'date'" class="calendar-picker">
            <input
                    (dateChange)="onDateChange($event)"
                    [matDatepicker]="datePicker"
                    [max]="maxDate"
                    [min]="minDate"
                    [value]="defaultDate"
                    matInput
                    placeholder="Select date"
                    readonly
            />

            <mat-datepicker-toggle
                    [for]="datePicker"
                    class="toggle"
                    matSuffix
            ></mat-datepicker-toggle>

            <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>


        <!-- Time Picker Input Field -->
        <div class="input-box d-flex justify-content-between cursor-pointer" *ngIf="type === 'time'">
            <mat-form-field appearance="outline" class="d-flex">
                <span [ngClass]="{ 'placeholder-text': !selectedTime }">{{ selectedTime ? selectedTime : 'Select Time' }}</span>
                <input
                        [id]="id"
                        matInput
                        [ngxTimepicker]="toggleTimepicker"
                        name="time"
                        readonly
                        [(ngModel)]="selectedTime"
                        (ngModelChange)="onTimeChange($event)"
                        class="time-input"
                >
                <!-- Remove ngx-material-timepicker-toggle -->
                <ngx-material-timepicker
                        [min]="minTime"
                        [defaultTime]="defaultTime"
                        #toggleTimepicker>
                </ngx-material-timepicker>
            </mat-form-field>

            <!-- Time Picker Icon -->
            <img
                    src="/assets/icons/ic-clock-light.svg"
                    class="cursor-pointer"
                    (click)="toggleTimepicker.open()"
            />
        </div>

        <!-- File Type Input Field Container -->
        <div
                *ngIf="type === 'file'"
                [ngClass]="{ 'error-file-container': showErrorMessage }"
                class="position-relative file-container"
        >
            <!-- Media File Name Text  -->
            <span *ngIf="uploadedMedia" class="uploaded-media-name">{{
                    uploadedMedia
                }}</span>

            <!-- Media File Placeholder Text -->
            <span *ngIf="!uploadedMedia" class="placeholder-text">{{
                    placeholder
                }}</span>

            <!-- File Type Input Field -->
            <input
                    (change)="uploadMedia($event, 'image')"
                    (click)="$event.stopPropagation()"
                    [id]="id"
                    [ngClass]="{ 'd-none': uploadedMedia }"
                    accept="{{ fileType ??  '.jpg, .jpeg, .png'}}"
                    class="input-file"
                    id="file"
                    name="file"
                    type="file"
            />

            <!-- File Loader -->
            <div *ngIf="isFileUploading" class="loader-container position-absolute">
                <img alt="loader" src="assets/icons/ic-button-loader.svg"/>
            </div>

            <!-- Upload/Remove Button -->
            <span
                    (click)="removeFile()"
                    *ngIf="!isFileUploading"
                    [ngClass]="{ 'remove-button': uploadedMedia }"
                    class="position-absolute upload-button"
            >{{ uploadedMedia ? 'Remove' : 'Upload' }}</span
            >
        </div>

        <!-- Password Icon Container -->
        <span
                (click)="togglePassword()"
                *ngIf="showPasswordIcon"
                class="material-icons password-icon position-absolute m-2"
        >
      {{ type === 'password' ? 'visibility_off' : 'visibility' }}
    </span>

        <!-- Color Picker Type Input Field -->
        <input
                (change)="inputValueHandler($event)"
                (colorPickerChange)="selectColor($event)"
                (keyup)="inputKeyEventHandler($event)"
                *ngIf="type === 'color-picker'"
                [(colorPicker)]="pickerColor"
                [disabled]="disabled"
                [id]="id"
                [ngClass]="{ 'disabled-input-box': disabled === true, 'required':  showErrorMessage }"
                [placeholder]="placeholder"
                [readonly]="true"
                [required]="required"
                [style.height]="height + 'px'"
                [type]="'text'"
                [value]="value"
                class="input-box cursor-pointer"
                name="{{ 'color-picker' }}"
        />

        <!-- Color Picker Icon Container -->
        <div
                (colorPickerChange)="selectColor($event)"
                *ngIf="type === 'color-picker'"
                [(colorPicker)]="pickerColor"
                [style.background]="pickerColor"
                class="color-icon position-absolute"
        ></div>

        <!-- Toggle Type Input Field -->
        <input
                (change)="inputValueHandler($event)"
                (keyup)="inputKeyEventHandler($event)"
                *ngIf="type === 'toggle'"
                [disabled]="false"
                [id]="id"
                [ngClass]="{ 'disabled-input-box': disabled === true, 'required':  showErrorMessage }"
                [placeholder]="placeholder"
                [readonly]="true"
                [required]="required"
                [style.height]="height + 'px'"
                [type]="'text'"
                [value]="value"
                class="input-box cursor-pointer"
                name="{{ name }}"
        />

        <!-- Toggle Switch -->
        <app-toggle
                *ngIf="type === 'toggle'"
                (click)="$event?.stopPropagation()"
                (clickEvent)="toggleInputEventHandler($event)"
                [isChecked]="isToggleChecked"
                class="end-icon-container position-absolute"
        ></app-toggle>

        <!-- Text Area Type Input Field -->
        <textarea
            #textAreaRef
            (change)="inputValueHandler($event)"
            (keyup)="inputKeyEventHandler($event)"
            *ngIf="type === 'textarea'"
            [disabled]="disabled"
            [maxLength]="maxLength"
            [placeholder]="placeholder"
            [value]="value"
            class="text-area"
            rows="4"
        ></textarea>

        <span *ngIf="inputInfoMessage" class="info-message">{{ inputInfoMessage }}</span>
    </div>
</div>
