<div class="form-group position-relative my-2">
    <label class="custom-toggle">
        <input
                (change)=" onSwitch($event)"
                [checked]="isChecked"
                [disabled]="disabled"
                name="status"
                type="checkbox"
        />
        <span class="slider-toggle round"> </span>
    </label>
</div>
