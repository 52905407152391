import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppButtonType } from '../../../constants/AppConstants';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: [ './button.component.scss' ],
})
export class ButtonComponent {
    @Input() label = 'Send';

    @Input() type: AppButtonType = AppButtonType.PRIMARY;

    @Input() height = '48px';

    @Input() width = '100%';

    @Input() disabled = false;

    @Input() borderRadius = '0.25rem';

    @Output() clickEvent: EventEmitter<any> = new EventEmitter();

    onClickButton () {
      this.clickEvent.emit('Event');
    }
}
