import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: [ './toggle.component.scss' ],
})
export class ToggleComponent {
    /** Property to hold switch button is checked or not */
    @Input() isChecked = false;

    @Input() disabled = false;

    /** Switch button click event emitter */
    @Output() clickEvent: EventEmitter<any> = new EventEmitter();

    /** Switch button click handling */
    onSwitch (event: any) {
      // this.isChecked = event?.target?.checked
      this.clickEvent.emit(event);
    }
}
