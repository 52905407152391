import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { AlertService } from 'src/app/services/alert/alert.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { SharedService } from 'src/app/services/shared/shared.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: [ './input.component.scss' ],
})
export class InputComponent implements OnInit {

    /** Property to hold the theme */
    @Input() public theme: string = 'light';

    /** Property to hold input name for data binding */
    @Input() public name: string = 'name';

    /** Property to hold the default height of input field */
    @Input() public height: string = '48';

    /** Property to hold the margin-top of the input field */
    @Input() public marginTop: string = '12';

    /** Property to hold the margin-bottom of the input field */
    @Input() public marginBottom: string = '0';

    /** Property to hold the input id */
    @Input() public id: string = '';

    /** Property to hold the type of input */
    @Input() public type: string = 'name';

    /** Property to hold input label text */
    @Input() labelText: string = '';

    /** Property to hold to show label text or not */
    @Input() public showLabel: boolean = true;

    /** Property to hold the placeholder */
    @Input() public placeholder: string = '';

    /** Property to hold the input error message */
    @Input() public errorMessage: string = '';

    /** Property to hold the input enable/disable value */
    @Input() public disabled: boolean = false;

    /** Property to hold if the input is required */
    @Input() public required: boolean = false;

    /** Property to hold if the input is mandatory */
    @Input() public isMandatory: boolean = true;

    /** Property to hold input file type */
    @Input() fileType: string = '.jpg, .jpeg, .png';

    /** Property to hold if file is uploaded or not */
    @Input() isUploaded: boolean = false;

    /** Property to hold uploaded file name */
    @Input() uploadedMedia: string = '';

    /** Property to hold if file is uploading */
    @Input() isFileUploading: boolean = false;

    /** Property to hold to show error message */
    @Input() showErrorMessage: boolean = false;

    /** Property to hold input value */
    @Input() public value: string = '';

    /**Property to hold input maximum length */
    @Input() public maxLength: number = 4096;

    /** Property to hold password icon visibility */
    @Input() public showPasswordIcon: boolean = false;

    /** Property to make input readonly */
    @Input() readonly: boolean = false;

    /** Property to hold the input info message */
    @Input() inputInfoMessage: string = '';

    /** Property to hold default color for color picker input field */
    @Input() pickerColor: string = '#000000';

    /** Property to hold toggle input value */
    @Input() isToggleChecked: boolean = false;

    /** Text area input reference */
    @ViewChild('textAreaRef', { static: false })
      textAreaRef!: ElementRef<HTMLTextAreaElement>;

    // START: Input Events
    /** Property to emit input change event */
    @Output() inputChange: EventEmitter<any> = new EventEmitter<any>();

    /** Property to emit input key up event */
    @Output() keyupEvent: EventEmitter<any> = new EventEmitter<any>();

    /** Property to emit toggle input event */
    @Output() toggleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    /** Property to emit error event */
    @Output() isError: EventEmitter<boolean> = new EventEmitter<boolean>();

    /** Property to emit address input event on address selection */
    @Output() addressEvent: EventEmitter<any> = new EventEmitter<{} | string>();
    // END: Input Events

    // START: Date Picker Fields
    /** Property to hold date picker reference */
    @ViewChild('datePicker') datePicker: MatDatepicker<any>;

    /** Property to hold calendar min date */
    @Input() minDate: Date;

    /** Property to hold calendar max date */
    @Input() maxDate: Date;

    /** Property to emit the event on date change */
    @Output() dateChangeEvent: EventEmitter<any> = new EventEmitter<any>();

    /** Property to hold current date */
    @Input() currentDateTime: Date = new Date();

    /** Property to hold default date */
    @Input() defaultDate: Date = this.currentDateTime;

    // END: Date Picker Fields

    // START: Time Picker Fields
    /** Property to hold selected time */
    @Input() selectedTime: string = '';

    /** Property to hold min time */
    @Input() minTime: string = '';

    /** Property to hold default time */
    @Input() defaultTime: string = '';

    /** Property to emit the event on time change */
    @Output() timeChangeEvent: EventEmitter<any> = new EventEmitter<any>();

    // END: Time Picker Fields

    constructor (
        private cdr: ChangeDetectorRef,
        public sharedService: SharedService,
        private alertService: AlertService,
        private utilityService: UtilityService,
    ) {
      // Set min date to 1 year ago from today
      this.minDate = new Date();
      this.minDate.setFullYear(this.minDate.getFullYear() - 1);

      // Set max date to today
      this.maxDate = new Date();
    }

    ngOnInit (): void {
      if (document.body.classList.contains('body-dark')) {
        this.theme = 'dark';
      } else {
        this.theme = 'light';
      }
    }

    /**
     * Property to emit onValue change event
     */
    public inputValueHandler (event: any, type?: string) {
      if (type === 'toggle') {
        this.isToggleChecked = !this.isToggleChecked;
      }

      // Emit Input Value
      this.inputChange.emit(event);
    }

    removeFile () {
      if (this.uploadedMedia || this.isUploaded) {
        this.isUploaded = false;
        this.uploadedMedia = '';
        this.inputChange.emit(this.uploadedMedia);
      }
    }

    /**
     * Property to emit onValue keyup event
     */
    public inputKeyEventHandler (event: any) {
      // if (this.type === 'number') {
      //     this.validateInputNumber(event);
      // }
      this.keyupEvent.emit(event);
    }

    /**
     * Password Toggle Click
     */
    public togglePassword () {
      if (this.type === 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    }

    validateInputNumber (event: Event) {
      return this.sharedService.checkInput(event, 'number', 2);
    }

    /**
     * On Date Input Click
     */
    onCalendarClick () {
      // Open Date Picker
      this.datePicker.open();
    }

    /**
     * Handle Time Change Event
     * @param event Selected Event
     */
    onTimeChange (event?: any): void {
      // Validation: Convert the selected time to a Date object
      const now = new Date();
      const selectedDate = new Date(now);
      const [ time, ampm ] = event.split(' ');
      const [ hours, minutes ] = time.split(':').map(Number);

      // eslint-disable-next-line
      selectedDate.setHours(ampm === 'PM' && hours !== 12 ? hours + 12 : hours === 12 && ampm === 'AM' ? 0 : hours);
      selectedDate.setMinutes(minutes);


      // Check if the selected time is less than the current time
      if (selectedDate < now) {
        this.errorMessage = 'Selected time cannot be earlier than the current time.';
        this.alertService.showAlert(this.errorMessage);
        this.selectedTime = '';
        this.timeChangeEvent.emit('');
        return;
      }
      else {
        this.errorMessage = '';
      }
      this.timeChangeEvent.emit(event);
    }


    /**
     * Handle Date Change Event
     * @param event Selected Event
     */
    onDateChange (event: any) {
      // Emit Date Select Event
      this.dateChangeEvent.emit(event);
    }

    /**
     * Handle Address Select Event
     */
    handleAddressChange (address: Address, event?: any) {
      const eventValue = event?.target?.value;
      if (address) {
        this.addressEvent.emit(this.utilityService.getLocation(address));
      } else {
        this.addressEvent.emit(eventValue);
      }
    }

    /**
     * Select Color
     */
    selectColor (event: any) {
      this.inputChange.emit(event);
      this.keyupEvent.emit(event);
    }

    uploadMedia (event: any, type?: string | null | undefined) {
      const inputElement = event.target as HTMLInputElement;
      if (!inputElement.files && !(inputElement.files.length > 0)) {
        return this.alertService.showAlert('Please select an image');
      }

      // Show Loading
      this.isFileUploading = true;

      // Prepare Form Data
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('type', type ?? '*/*');
      formData.append('file', file, file.name);

      // Get File Size
      const fileSize = file.size;
      const fileSizeReadable = Math.round(fileSize / 1024);

      // Check File Size
      if (fileSizeReadable > 20480) {
        this.alertService.showAlert(
          'File too Big, please select a file less than 20MB',
        );
        return;
      }

      // Show Loading
      this.isFileUploading = false;
    }

    // API Call - Upload Media

    /**
     * Handle toggle input event
     * @param event of toggle
     */
    toggleInputEventHandler (event: any) {
      this.toggleChange.emit(event);
    }
}
