export class AppConstants {
  /**
     * Keys for User Types
     */
  static UserType = {
    ADMIN: 'admin',
    SHOP: 'shop',
  };

  /**
     * Argument Keys
     */
  static Argument = {
    _ID: '_id',
  };

  /**
     * Keys for Button Types
     */
  static ButtonType = {
    PRIMARY: 'primary',
    ERROR: 'error',
    EDIT: 'edit',
    DELETE: 'delete',
    DISABLED: 'disabled',
    BLOCK: 'block',
    ACCEPT: 'accept',
    REJECT: 'reject',
  };

  /**
     * Keys for Local Storage Types
     */
  static LocalStorageKeys = {
    AUTH_TOKEN: 'auth-token',
    USER_TYPE: 'user-type',
    CATEGORY_IDS: 'categoryIds',
    VEC_LOGIC_EMBEDDING_IDS: 'vecLogicEmbeddingIds',
    CATEGORY_NAME: 'categoryName',
    VERIFY_AUTH_TOKEN: 'verify-auth-token',
  };

  /**
     * Keys for Pagination Component
     */
  static PaginationKey = {
    PREVIOUS_PAGE: 'previous-page',
    NEXT_PAGE: 'next-page',
  };

  /**
     * Keys for Action Button Types
     */
  static ActionButton = {
    UPLOAD_DATA: 'UPLOAD_DATA',
    CLEAR_DATA: 'CLEAR_DATA',
    ADD_SHOP: 'ADD_SHOP',
    ADD_CATEGORY: 'ADD_CATEGORY',
    SET_PRIORITY: 'SET_PRIORITY',
    SAVE_PRIORITY: 'SAVE_PRIORITY',
    ADD_PROMPT: 'ADD_PROMPT',
    ASK_ME_ANYTHING_CONFIGURATION: 'ASK_ME_ANYTHING_CONFIGURATION',
    ADD_DOCUMENT: 'ADD_DOCUMENT',
    ADD_BROADCAST: 'ADD_BROADCAST',
  };
}

/**
 * Keys for Sidebar Tabs Navigation Component
 */
export enum TableColumnType {
    input_text = 'inputText',
    toggle = 'toggle',
    checkbox = 'checkbox',
    actions = 'actions',
    select = 'select',
    drag = 'drag',
    color = 'color',
    PICTURE = 'PICTURE',
    ACTION_BUTTON = 'ACTION_BUTTON',
    HTML_CONTENT = 'HTML_CONTENT',
}

/**
 * Keys for Sidebar Tabs Navigation Component
 */
export enum FontType {
    primary = 'primary',
    error = 'error',
}

/**
 * Keys for Alert Position
 */
export enum AlertPosition {
    TOP_RIGHT = 'top-right',
    TOP_LEFT = 'top-left',
    BOTTOM_RIGHT = 'bottom-right',
    BOTTOM_LEFT = 'bottom-left',
    CENTER = 'center',
    CENTER_TOP = 'center-top',
    BOTTOM_CENTER = 'bottom-center',
}

/**
 * Keys for Alert Types
 */
export enum AlertType {
    SUCCESS = 'success',
    ERROR = 'error',
    INFO = 'info',
    WARNING = 'warning',
}

/**
 * Keys for User Types
 */
export enum UserType {
    ADMIN = 'admin',
    SHOP = 'shop',
}

/**
 * Keys for Button Component
 */
export enum AppButtonType {
    PRIMARY = 'primary', // Primary color "Solid" button
    EDIT = 'edit', // Primary color "Solid" button
    ACCEPT = 'accept', // Primary color "Solid" button
    DELETE = 'delete', // Red color "Solid" button
    DANGER = 'danger', // Red color "Solid" button
    REJECT = 'reject', // Red color "Solid" button
    SUCCESS = 'success', // Green color "Solid" button
    OUTLINE = 'outline', // Primary color "Outline" button
    TEXT = 'text', // Primary color "Text" button
    TEXT_UNDERLINE = 'text-underline', // Primary color "Text Underline" button
    DISABLED = 'disabled', // Not Implemented
    BLOCK = 'block', // Not Implemented
    WARNING = 'warning', // Not Implemented
    SECONDARY = 'secondary', // Not Implemented
}
