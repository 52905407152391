import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { AlertBarComponent } from './alert-bar/alert-bar.component';
import { ButtonComponent } from './button/button.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { CountryDropdownComponent } from './country-dropdown/country-dropdown.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DropdownInputComponent } from './dropdown-input/dropdown-input.component';
import { FormsModule } from '@angular/forms';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { HtmlEditorInputComponent } from './html-editor-input/html-editor-input.component';
import { InputComponent } from './input/input.component';
import { LoaderComponent } from './loader/loader.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MessageModalComponent } from './message-modal/message-modal.component';
import { ModalComponent } from './modal/modal.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { PaginatedDropdownInputComponent } from './paginated-dropdown-input/paginated-dropdown-input.component';
import { PaginationComponent } from './pagination/pagination.component';
import { PasswordMaskDirective } from './directives/password.directive';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TableComponent } from './table/table.component';
import { ToggleComponent } from './toggle/toggle.component';

@NgModule({
  declarations: [
    ButtonComponent,
    TableComponent,
    SidebarComponent,
    ModalComponent,
    InputComponent,
    CountryDropdownComponent,
    ToggleComponent,
    PaginationComponent,
    CountryDropdownComponent,
    DropdownInputComponent,
    CheckboxComponent,
    PasswordMaskDirective,
    AlertBarComponent,
    LoaderComponent,
    MessageModalComponent,
    PaginatedDropdownInputComponent,
    HtmlEditorInputComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    GooglePlaceModule,
    ColorPickerModule,
    DragDropModule,
    NgOptimizedImage,
    CKEditorModule,
    NgxMaterialTimepickerModule,
  ],
  exports: [
    ButtonComponent,
    ModalComponent,
    InputComponent,
    CountryDropdownComponent,
    SidebarComponent,
    ToggleComponent,
    TableComponent,
    PaginationComponent,
    CountryDropdownComponent,
    CheckboxComponent,
    DropdownInputComponent,
    PasswordMaskDirective,
    AlertBarComponent,
    LoaderComponent,
    MessageModalComponent,
    GooglePlaceModule,
    DragDropModule,
    PaginatedDropdownInputComponent,
    HtmlEditorInputComponent,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class SharedModule {
}
