<div class="pagination-container d-flex justify-content-end">
  <div class="right-container">
    <img
      src="/assets/icons/ic-chevron-left-light.svg"
      class="arrow arrow-icon"
      (click)="onPreviousClick()"
    />
    <span class="active-page">{{ activePage }}</span>
    <img
      src="/assets/icons/ic-chevron-right-light.svg"
      class="arrow arrow-icon"
      (click)="onNextClick()"
    />
  </div>
</div>
