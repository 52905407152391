/**
 * Extension Function Related to Network Call
 * */

/**
 * @return TRUE if HTTP Code is related with Authentication Exception
 * */
export function isAuthError (code: number): boolean {
  return code == 401;
}
