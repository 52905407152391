/**
 * Keys for Tabs Component
 */
export enum TabKey {
    SUGGESTION_PROMPTS = 'SUGGESTION_PROMPTS',
    ADDITIONAL_PROMPTS = 'ADDITIONAL_PROMPTS',
    CSV_DATA = 'CSV_DATA',
    BROADCAST = 'BROADCAST',
}


/** Input Field Keys to check input value */
export const PromptInputFieldKey = {
  ADDITIONAL_PROMPT: 'ADDITIONAL_PROMPT',
};

/** Input Field Keys to check input value */
export const BroadcastInputFieldKey = {
  BROADCAST: 'BROADCAST',
};

/** Property to handle add/edit prompt form values errors */
export const showPromptError = {
  prompt: false,
};

/** Property to handle add/edit broadcast form values errors */
export const showBroadcastError = {
  message: false,
};

/** Property to handle add/edit prompt form error messages */
export const promptErrorMessage = {
  prompt: '',
};

/** Property to handle add/edit broadcast form error messages */
export const broadcastErrorMessage = {
  message: '',
};

