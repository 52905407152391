import { AlertService } from '../../alert/alert.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { NavigationService } from '../../navigation/navigation.service';
import { NetworkResponseType } from '../../network/NetworkResponse';
import { ProfileModel } from 'src/app/models/ProfileModel';
import { SharedService } from '../../shared/shared.service';
import { isAuthError } from '../../network/Extensions';

@Injectable({
  providedIn: 'root',
})
export class CommonService {

  /** Property to hold the device type value */
  isMobile: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor (
    private authService: AuthenticationService,
    private sharedService: SharedService,
    private alertService: AlertService,
    private navigationService: NavigationService,
  ) {
    // Empty Constructor
  }

  /**
   * Set the value if device is mobile
   */
  updateIsMobileValue (newValue: any): void {
    this.isMobile.next(newValue);
  }

  /**
   * API Call - Get Profile
   */
  async getProfile (isLoading: boolean = true): Promise<ProfileModel> {
    if (isLoading) {
      // Show Loading
      this.sharedService.showLoading();
    }

    // Get API Response
    const apiResponse = await this.authService.getProfile();

    // Consume Response
    switch (apiResponse?.responseType) {
    case NetworkResponseType.SUCCESS:
      if (apiResponse?.response?.success) {
        if (apiResponse?.response?.data !== null) {
          // Dismiss Loading
          this.sharedService.dismissLoading();

          // Set Data in Shared Service
          this.sharedService.setProfileData(apiResponse?.response);

          // Return Profile Data Response
          return apiResponse?.response;
        }
        // Dismiss Loading
        this.sharedService.dismissLoading();
        return;
      }

      // Show Message
      this.alertService.showAlert(apiResponse.response.message);
      break;

    case NetworkResponseType.API_ERROR:
      // Show Message
      this.alertService.showAlert(apiResponse.response.message);

      // Check If Authentication Error
      if (isAuthError(apiResponse.code)) {
        // Clear User Data
        await this.sharedService.clearUserData();
        // Notify to Navigate
        this.navigationService.logout();
        return;
      }
      break;

    case NetworkResponseType.UNKNOWN_ERROR:
      // Show Message
      this.alertService.showAlert(apiResponse.response.message);
      break;
    }

    // Dismiss Loading
    this.sharedService.dismissLoading();
  }

  private showNavigation = true;

  setShowNavigation (show: boolean): void {
    this.showNavigation = show;
  }

  getShowNavigation (): boolean {
    return this.showNavigation;
  }
}
