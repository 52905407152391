import { AppConstants, TableColumnType } from '../../../constants/AppConstants';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AlertService } from 'src/app/services/alert/alert.service';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: [ './table.component.scss' ],
})
export class TableComponent implements OnInit, OnChanges {
    /** Property to handle theme */
    @Input() public theme: string = 'light';

    /** Property to hold title value */
    @Input() title: string = '';

    /** Property to display checkboxes in table */
    @Input() showCheckbox: boolean = true;

    // START - Top Bar Content - ***********************************
    /** Property to show top bar */
    @Input() showTopBar: boolean = true;

    /** Property to show delete icon */
    @Input() showDeleteIcon: boolean = true;

    /** Property to show search icon */
    @Input() showSearchInput: boolean = true;

    /** Property to search input placeholder */
    @Input() searchPlaceholder: string = 'Search...';

    /** Property to emit search event */
    @Output() searchEvent: EventEmitter<any> = new EventEmitter();

    /** Property to show buttons */
    @Input() showTopButtons: boolean = true;

    /** Property to show filter bar Buttons */
    @Input() topButtons = [];

    /** Property to handle top bar button click event */
    @Output() topButtonsEvent: EventEmitter<any> = new EventEmitter();
    // END - Top Bar Content - ***********************************

    // START - Pagination Content - ***********************************
    /** Property to display pagination in table */
    @Input() public showPagination: boolean = true;

    /** Active page count */
    @Input() activePage: number = 1;

    /** Total entries count */
    @Input() totalCount: number = 0;

    /** Property to emit current page number */
    @Output() currentPage: EventEmitter<any> = new EventEmitter<any>();
    // END - Pagination Content - ***********************************

    // START - Table Content - ***********************************
    /** Property to hold dummy values */
    @Input() tableData: any = {
      thead: [],
    };
    // END - Table Content - ***********************************

    // TODO - Optimize/Check Below Values

    /** Property to emit event any action event is clicked */
    @Output() actionEvent: EventEmitter<any> = new EventEmitter();

    /** Property to emit event any action event is clicked */
    @Output() toggleEvent: EventEmitter<any> = new EventEmitter();

    /** Property to emit event any action event is change */
    @Output() checkboxClickHandler: EventEmitter<any> = new EventEmitter();

    /** Property to emit event when delete icon is clicked */
    @Output() deleteIconEvent: EventEmitter<any> = new EventEmitter();

    /** Property to emit event when refresh icon is clicked */
    @Output() refreshIconEvent: EventEmitter<any> = new EventEmitter();

    /** Property to handle table item click event */
    @Output() itemSelectEvent: EventEmitter<any> = new EventEmitter();

    /** Property to handle icons click */
    @Output() iconClickEvent: EventEmitter<any> = new EventEmitter();

    /** Property to hold selected item for selected checkboxes */
    public selectedElements: string[] = [];

    /** Property to hold user type value */
    userType: string = '';

    @Input() showNextPage: boolean = false;
    @Input() showPreviousPage: boolean = false;

    // START - Priority Type Table Content - ***********************************
    /** Property to emit load more button click */
    @Output() loadMoreEvent: EventEmitter<any> = new EventEmitter<any>();

    /** Property to emit updated list */
    @Output() dragEvent: EventEmitter<any> = new EventEmitter<any>();

    /** Property to hold isItemDraggable value */
    @Input() isDragEnabled: boolean = false;

    /** Property to hold end scrolling enabled value */
    @Input() isEndScrollEnabled: boolean = false;

    // END - Priority Type Table Content - ***********************************

    /**
     * Primary Constructor
     */
    constructor (
        public sharedService: SharedService,
        public alertService: AlertService,
    ) {
      // Empty Constructor
    }

    // Init Method
    ngOnInit (): void {
      // Get Profile Data
      this.sharedService.profileModel.subscribe((profile) => {
        this.userType = profile?.data?.type?.toUpperCase();
      });

      if (document.body.classList.contains('body-dark')) {
        this.theme = 'dark';
      } else {
        this.theme = 'light';
      }

      this.sharedService.refreshRoute.subscribe((route: string) => {
        if (route) {
          // Set active page number
          this.activePage = 1;
        }
      });
    }

    /**
     * On Changes Method
     */
    ngOnChanges (): void {

      // Set Next Page Icon Visibility
      this.showNextPage = this.activePage < this.totalCount / 20;

      // Set Previous Page Icon Visibility
      this.showPreviousPage = this.activePage > 1;
    }

    /**
     * Top Bar Buttons Click Event
     */
    triggerClickEvent (button: any) {
      this.topButtonsEvent.emit(button);
    }

    /**
     * Action Buttons Click Event
     */
    public actionEventHandler (action: any, index: number, item: any) {
      if (action.type === 'delete') {
        this.selectedElements = [];
      }
      this.actionEvent.emit({ action, index, item });
    }

    /**
     * Clickable Title Click Event
     */
    public onClickActionHandler (header: any, item: any): void {
      if (header?.isClickable) {
        this.itemSelectEvent.emit(item);
      }
    }

    /**
     * Delete Icon Click
     */
    public onDeleteIconClick () {
      if (this.selectedElements.length > 0) {
        this.deleteIconEvent.emit();
      }
    }

    /**
     * Checkbox Click Handling
     */
    public checkboxHandler (id: string, type: string) {
      if (type === 'single') {
        if (this.selectedElements.includes(id)) {
          this.selectedElements = this.selectedElements.filter((i) => i !== id);
        } else {
          this.selectedElements.push(id);
        }
      } else {
        if (this.selectedElements.length === this.tableData.tbody.length) {
          this.selectedElements = [];
        } else {
          this.selectedElements = [];
          this.tableData.tbody.map((item: any) =>
            this.selectedElements.push(item._id),
          );
        }
      }
      this.checkboxClickHandler.emit(this.selectedElements);
    }

    /**
     * Clickable Title Click Event
     */
    getClass (header: any, body: any) {
      const colorSchema =
            header.type === 'select' ? header.dropdownOptions : header?.color || [];
      if (!colorSchema.length) {
        return '';
      }
      const textIndex = colorSchema.findIndex(
        (item: { key: string; color: string }) => item.key === body[header.id],
      );
      return colorSchema[textIndex]?.color || '';
    }

    public toggleActionHandler (event: any, index: number, item: any): void {
      this.toggleEvent.emit({ event, item, index });
    }

    /** Search event handling */
    public searchHandler (event: any): void {
      this.searchEvent.emit(event?.target?.value);
    }

    /**
     * Previous Page Icon Click
     */
    onPreviousClick (): void {
      // Check if active page is greater than 1 then user can click on previous page icons
      if (this.activePage > 1) {
        this.activePage--;
      }

      this.currentPage.emit({ page: this.activePage, event: 'previous' });
    }

    /**
     * Next Page Icon Click
     */
    onNextClick (): void {
      // Check if active page is less than 2 then user can click on next page icons
      if (this.activePage < this.totalCount / 20) {
        this.activePage++;
      }
      this.currentPage.emit({ page: this.activePage, event: 'next' });
    }

    /**
     * Handle selected item when dropped to an index
     * @param event updated list after drag & drop
     */
    drop (event: CdkDragDrop<any[]>) {

      // Move item in the list
      moveItemInArray(this.tableData?.tbody, event?.previousIndex, event?.currentIndex);

      // Get the updated list after the drop
      const updatedList: any[] = [ ...this.tableData?.tbody ];

      this.dragEvent.emit(updatedList);
    }

    /**
     * Load More Button Click
     */
    onLoadMoreClick () {
      if (this.totalCount) {
        if (this.totalCount > this.tableData?.tbody?.length) {
          this.loadMoreEvent.emit(true);
        }
      } else {
        this.loadMoreEvent.emit(true); // TODO - Need to update from backend in all APIs then we can remove this
      }
    }

    isValidUrl (url: string) {
      return this.sharedService.isValidImageUrl(url);
    }

    protected readonly TableColumnType = TableColumnType;
    protected readonly AppConstants = AppConstants;
}
