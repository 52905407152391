import { AnalyticsModel } from '../../../models/AnalyticsModel';
import { AppConstants } from '../../../constants/AppConstants';
import { BaseModel } from 'src/app/models/BaseModel';
import { CustomerUserModel } from '../../../models/CustomerUserModel';
import { ForgotPasswordModel } from 'src/app/models/ForgotPasswordModel';
import { HttpMethod } from '../../network/HttpMethod';
import { Injectable } from '@angular/core';
import { LoginModel } from 'src/app/models/LoginModel';
import { NetworkResponse } from '../../network/NetworkResponse';
import { NetworkService } from '../../network/network.service';
import { ProfileModel } from 'src/app/models/ProfileModel';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor (private networkService: NetworkService) {
    //Empty Constructor
  }

  /**
     * Request to Login User
     *
     * @param {object} body - Request Body
     * @return Promise with Response
     */
  login (body: object): Promise<NetworkResponse<LoginModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.POST,
      url: 'authentication/public/admin-login',
      data: body,
    });
  }

  /**
     * Request to Get User Profile
     *
     * @return Promise with Response
     */
  getProfile (): Promise<NetworkResponse<ProfileModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.GET,
      url: 'authentication/admin/my-profile',
    });
  }

  /**
     * Request to Send Forgot Password OTP
     *
     * @param {object} body - Request Body
     * @return Promise with Response
     */
  forgotPassword (body: object): Promise<NetworkResponse<ForgotPasswordModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.POST,
      url: 'authentication/public/admin-forgot-password',
      data: body,
    });
  }

  /**
     * Request to Verify Email OTP
     *
     * @param {object} body - Request Body
     * @return Promise with Response
     */
  verifyEmail (body: object): Promise<NetworkResponse<LoginModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.POST,
      url: 'authentication/public/admin-forgot-password/verify',
      data: body,
    });
  }

  /**
     * Request to Set New Password
     *
     * @param {object} body - Request Body
     * @return Promise with Response
     */
  resetPassword (body: object): Promise<NetworkResponse<BaseModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.PUT,
      url: 'authentication/admin/reset-password',
      data: body,
      token: localStorage.getItem(AppConstants.LocalStorageKeys.VERIFY_AUTH_TOKEN),
    });
  }

  /**
     * Request to Logout User
     *
     * @return Promise with Response
     */
  logout (): Promise<NetworkResponse<BaseModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.POST,
      url: 'authentication/admin/logout',
    });
  }

  /**
     * Request to Change Password
     *
     * @param {object} body - Request Body
     * @return Promise with Response
     */
  changePassword (body: object): Promise<NetworkResponse<BaseModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.PUT,
      url: 'authentication/admin/change-password',
      data: body,
    });
  }

  /**
     * Request to Update Profile
     *
     * @param {object} body - Request Body
     * @return Promise with Response
     */
  updateProfile (body: object): Promise<NetworkResponse<ProfileModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.PUT,
      url: 'authentication/admin/my-profile/edit',
      data: body,
    });
  }

  /**
     * Request to Request Delete Account OTP
     *
     * @param {object} body - Request Body
     * @return Promise with Response
     */
  requestDeleteAccountOTP (body: object): Promise<NetworkResponse<BaseModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.POST,
      url: 'authentication/public/send-otp',
      data: body,
    });
  }

  /**
     * Request to Verify Phone Number OTP
     *
     * @param {object} body - Request Body
     * @return Promise with Response
     */
  verifyDeleteAccountOTP (body: object): Promise<NetworkResponse<LoginModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.POST,
      url: 'authentication/public/verify-otp',
      data: body,
    });
  }

  /**
     * Request to Request Delete Account OTP
     *
     * @return Promise with Response
     */
  deleteAccount (token: string): Promise<NetworkResponse<BaseModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.PUT,
      url: 'authentication/user/delete-account',
      token: token,
    });
  }

  /**
   * Request to Get Customers List
   *
   * @param {string} page - Page Number
   * @param {string} search - Search Input
   * @return Promise with Response
   */
  getCustomerList (
    page: string = '',
    search: string = '',
  ): Promise<NetworkResponse<CustomerUserModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.GET,
      url: 'authentication/user/listing',
      queryParams: {
        ...(page && { page: page }),
        ...(search && { searchText: search }),
        limit: 20,
      },
    });
  }

  /**
   * Request to Update Customer
   *
   * @param {object} body - Request Body
   * @param {string} _id - Customer ID
   * @return Promise with Response
   */
  updateCustomer (body: object, _id: string): Promise<NetworkResponse<BaseModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.PUT,
      url: `authentication/user/updateStatus/${ _id }`,
      data: body,
    });
  }

  /**
   * Request to Get Analytics Data
   *
   * @return Promise with Response
   * */
  getAnalyticsData (): Promise<NetworkResponse<AnalyticsModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.GET,
      url: 'aggregation/analytics',
    });
  }
}
