<div class="dropdown-container position-relative"
     [style.margin-top]="marginTop + 'px'"
     [style.margin-bottom]="marginBottom + 'px'">
    
  <!-- Label With Error -->
  <label
    class="label lp position-relative mb-1"
    *ngIf="showLabel && labelText"
    [ngClass]="{ 'required-text': showErrorMessage }"
    >{{ showErrorMessage ? errorMessage : labelText
    }}<span class="mandatory" *ngIf="showLabel && isMandatory"> *</span></label
  >

  <!-- Input Box Container -->
  <div
    class="input-box-container position-relative p-0"
    (click)="toggleDropdown($event)"
    [id]="id"
  >
    <!-- Input Box -->
    <div
      class="input-box"
      [ngClass]="{ 'required-input-box': showErrorMessage }"
    >
      <!-- Input Text -->
      <p *ngIf="value">{{ value }}</p>

      <!-- Placeholder Text -->
      <p class="placeholder-text" *ngIf="!value">
        {{ placeholder }}
      </p>
    </div>

    <!-- Dropdown Arrow Icon -->
    <img
      class="drop-down-arrow"
      [ngClass]="{ 'drop-down-arrow-active': showDropDown }"
      src="assets/icons/ic-chevron-down-{{ theme }}.svg"
      alt=""
    />
  </div>

  <!-- Dropdown Box Container -->
  <div
    *ngIf="showDropDown"
    class="dropdown-box-container"
    [ngClass]="{ 'reverse-drop-down': isReversed }"
  >
    <!-- Search Input Container -->
    <div
      class="search-input-container position-relative"
      *ngIf="enableSearch"
      (click)="$event.stopPropagation()"
    >
      <!-- Search Input Field -->
      <input
        type="text"
        (keyup)="searchEventHandler($event)"
        placeholder="Search"
        class="search-field"
      />

      <!-- Search Icon -->
      <img
        src="assets/icons/ic-search-light.svg"
        alt=""
        class="position-absolute search-icon"
      />
    </div>

    <!-- Dropdown Options Container -->
    <ul class="dropdown-options-container mb-0 p-0">
      <!-- Selected Options List -->
      <li
        (click)="onOptionClick(item)"
        [ngClass]="{ disabled: item?.isDisabled }"
        class="option-item-box justify-content-between"
        *ngFor="let item of optionList; let i = index"
      >
        <!-- Selected Option Text -->
        <span [ngClass]="{ 'selected-option': item?.isSelected }">{{
          item?.title
        }}</span>

        <!-- Selected Option Check Icon -->
        <img src="assets/icons/ic-check.svg" alt="" *ngIf="item?.isSelected" />
      </li>

      <!-- No Data Found Container -->
      <div *ngIf="!(optionList)" class="no-data-found-container">
        <!-- No Data Found Text -->
        <span class="no-data-found-text">No Data Found</span>
      </div>
    </ul>

    <!-- Done Button Container -->
    <div class="button-container" *ngIf="!isSingleSelection && optionList">
      <!-- Done Button -->
      <app-button
        type="text"
        label="Done"
        class="user-add-button"
        (clickEvent)="onDoneClick()"
      ></app-button>
    </div>
  </div>
</div>
