import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DropdownService } from 'src/app/services/dropdown/dropdown.service';

declare let $: any;

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: [ './modal.component.scss' ],
})
export class ModalComponent implements OnChanges {
    @Input() id = 'modal';
    @Input() header = '';
    @Input() subHeader = '';
    @Input() theme = 'light';
    @Input() showModal = false;
    @Input() showHeader = true;
    @Input() showCloseButton = true;
    @Input() showSuccessImage = false;
    @Output() userInput: EventEmitter<any> = new EventEmitter();
    staticText: any;
    @ViewChild('modal', { static: true }) modal: ElementRef;

    constructor (private dropdownService: DropdownService) {
      //Empty Constructor
    }

    ngOnChanges (changes: SimpleChanges): void {
      if (document.body.classList.contains('body-dark')) {
        this.theme = 'dark';
      } else {
        this.theme = 'light';
      }

      if (changes['showModal']?.currentValue) {
        return $(document.getElementById(this.id)).modal('show');
      }
      return $(document.getElementById(this.id)).modal('hide');
    }

    action (status: boolean): void {
      this.userInput.emit(status);
    }

    closeDropdowns (event: any): void {
      event.stopPropagation();
      event.preventDefault();
      const modalContent = this.modal.nativeElement;
      if (
        event.target &&
            modalContent.contains(event.target as Node) &&
            !event.target.classList.contains('dropdown')
      ) {
        this.dropdownService.openDropdown.next('');
      }
    }
}
