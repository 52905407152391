/* eslint-disable max-lines */
import { ActivatedRoute, Router } from '@angular/router';
import { AlertType, AppConstants } from 'src/app/constants/AppConstants';
import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationScreenRoute, SideBarTabs } from 'src/app/constants/navigation-screen';
import { NetworkResponse, NetworkResponseType } from '../../../services/network/NetworkResponse';
import {
  PromptInputFieldKey,
  promptErrorMessage,
  showPromptError,
} from '../../dashboard/screens/manage-categories/category-actions/declarations';
import { AdditionalPromptsModel } from '../../../models/AdditionalPromptsModel';
import { AdditionalPromptsService } from '../../../services/api-service/additional-prompts/additional-prompts.service';
import { AlertService } from '../../../services/alert/alert.service';
import { CommonService } from 'src/app/services/api-service/common/common.service';
import { ModalService } from '../../../services/modal/modal.service';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { ProfileData } from 'src/app/models/ProfileData';
import { ProfileModel } from 'src/app/models/ProfileModel';
import { RedirectHandlerService } from 'src/app/services/redirect-handler/redirecthandler.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { SidebarTab } from '../../../models/SidebarTab';
import { Subscription } from 'rxjs';
import { SuggestionPromptsData } from '../../../models/SuggestionPromptsData';
import { isAuthError } from '../../../services/network/Extensions';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: [ './sidebar.component.scss' ],
})
export class SidebarComponent implements OnInit {

  /** Property to hold the theme */
  theme: string = 'light';

  /** Property to hold the sidebar tabs */
  sideBarTabs: SidebarTab[] = [];

  /** Property to hold the active sidebar tabs */
  activeTab: SideBarTabs = SideBarTabs.INVENTORY;

  /** Property to hold the sidebar tab subscription */
  tabSubscription$: Subscription;

  /** Property to hold the sidebar collapsed/non-collapsed */
  isSideBarCollapsed: boolean = false;

  /** Property to hold if device is mobile */
  isMobile: boolean = false;

  /** Property to hold user data */
  profileData: ProfileData;

  /** Property to hold user data */
  user: ProfileData;

  /** Default Admin Sidebar Tabs */
  adminSidebarTabs: SidebarTab[] = [
    {
      title: 'Analytics',
      key: SideBarTabs.ANALYTICS,
      image: `ic-analytics-${ this.theme }.svg`,
    },
    {
      title: 'Users',
      key: SideBarTabs.USERS,
      image: `ic-users-${ this.theme }.svg`,
    },
    {
      title: 'Data',
      key: SideBarTabs.CATEGORY_INVENTORY_TABS,
      image: `ic-inventory-${ this.theme }.svg`,
    },
    {
      title: 'Manage Categories',
      key: SideBarTabs.MANAGE_CATEGORIES,
      image: `ic-manage-categories-${ this.theme }.svg`,
    },
    {
      title: 'Ask Me Anything',
      key: SideBarTabs.ASK_ME_ANYTHING,
      image: `ic-trending-now-${ this.theme }.svg`,
    },
    {
      title: 'CMS',
      key: SideBarTabs.CMS,
      image: `ic-cms-${ this.theme }.svg`,
    },
    {
      title: 'Moderate Requests',
      key: SideBarTabs.MODERATE_REQUESTS,
      image: `ic-moderate-request-${ this.theme }.svg`,
    },
    {
      title: 'Account',
      key: SideBarTabs.ACCOUNT,
      image: `ic-profile-${ this.theme }.svg`,
    },
    {
      title: 'Logout',
      key: SideBarTabs.LOGOUT,
      image: `ic-logout-${ this.theme }.svg`,
    },
  ];

  /** Default Shop Sidebar Tabs */
  shopSidebarTabs: SidebarTab[] = [
    {
      title: 'Data',
      key: SideBarTabs.INVENTORY,
      image: `ic-inventory-${ this.theme }.svg`,
    },
    {
      title: 'Customer Chat',
      key: SideBarTabs.CUSTOMER_CHAT,
      image: `ic-chat-${ this.theme }.svg`,
    },
    {
      title: 'Suggestion Prompts',
      key: SideBarTabs.SUGGESTION_PROMPTS,
      image: `ic-suggest-prompt-${ this.theme }.svg`,
    },
    {
      title: 'Additional Prompts',
      key: SideBarTabs.ADDITIONAL_PROMPTS,
      image: `ic-additional-prompt-${ this.theme }.svg`,
    },
    {
      title: 'Broadcast',
      key: SideBarTabs.BROADCAST,
      image: `ic-trending-now-${ this.theme }.svg`,
    },
    {
      title: 'Account',
      key: SideBarTabs.ACCOUNT,
      image: `ic-profile-${ this.theme }.svg`,
    },
    {
      title: 'Logout',
      key: SideBarTabs.LOGOUT,
      image: `ic-logout-${ this.theme }.svg`,
    },
  ];

  // START: Additional Prompt Handling Data
  /** Property to handle create/update additional prompt form values */
  additionalPromptData: SuggestionPromptsData;

  /** Property to hold Add/Edit additional prompt status */
  isUpdatingAdditionalPrompt: boolean = false;

  /** Property to handle error messages status */
  public showPromptError: any;

  /** Property to handle input field errors */
  public promptErrorMessage: any;

  /** Property to handle input field type */
  public promptInputFieldKey = PromptInputFieldKey;

  /** Property to hold modal visibility status */
  showAdditionalPromptModal: boolean = false;
  // END: Additional Prompt Handling Data

  /** Primary Constructor */
  constructor (
        private sharedService: SharedService,
        private RedirectHandlerService: RedirectHandlerService,
        private router: Router,
        private commonService: CommonService,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private navigationService: NavigationService,
        private modalService: ModalService,
        private additionalPromptsService: AdditionalPromptsService,
  ) {
    this.checkDevice();
    this.setSidebarStatus();

    this.tabSubscription$ = sharedService.activeTab.subscribe((tab) => {
      this.activeTab = tab;
    });

    /* Get route query parameters */
    this.route.queryParams.subscribe((params) => {
      try {
        if (params.active) {
          this.sharedService.activeTab.next(params.active);
        }
      } catch (err) {
        this.alertService.showAlert('Something went wrong.');
      }
    });
  }

  // Init Method
  ngOnInit (): void {
    // API Call - Get Profile
    this.commonService.getProfile().then((profileModel: ProfileModel) => {
      this.profileData = this.sharedService.createDeepCopy(profileModel?.data);

      // Set Sidebar Tabs with permission checks
      this.setSidebarTabs();
    });

    // Set Profile Data
    this.sharedService.profileModel.subscribe((profile) => {
      this.profileData = this.sharedService.createDeepCopy(profile?.data);

      // Set Initial Active Tab
      this.setInitialActiveTab();
    });

    // Initialize Errors
    this.showPromptError = showPromptError;
    this.promptErrorMessage = promptErrorMessage;

    // Initialize Additional Prompt Input Values
    this.initializeAdditionalPromptInputValues();
  }

    /** Check window on resize */
    @HostListener('window:resize', [ '$event' ])
  onWindowResize (): void {
    this.checkDevice();
    this.setSidebarStatus();
  }

    /**
     * Check Device and Set Device
     */
    private checkDevice (): void {
      this.isMobile = window.innerWidth <= 992;
      this.commonService.updateIsMobileValue(this.isMobile);
    }

    /**
     * Toggle Sidebar
     */
    toggleSidebar (): void {
      /* Update Sidebar open/collapsed status */
      this.isSideBarCollapsed = !this.isSideBarCollapsed;

      /*  Update Sidebar open/collapsed status */
      this.sharedService.sideBarStatus.next(this.isSideBarCollapsed);
    }

    /**
     * Method to return active tab selection
     * @returns true/false if active
     */
    isActiveTab (key: string): boolean {
      return this.activeTab === key;
    }

    /**
     * Set sidebar status (collapsed/open)
     */
    setSidebarStatus (): void {
      this.isSideBarCollapsed = this.isMobile;
      this.sharedService.sideBarStatus.next(this.isSideBarCollapsed);
    }

    /**
     * Set initial active tab
     */
    setInitialActiveTab (): void {
      if (this.router.url.includes(NavigationScreenRoute.ANALYTICS)) {
        this.sharedService.activeTab.next(SideBarTabs.ANALYTICS);
      } else if (this.router.url.includes(NavigationScreenRoute.INVENTORY)) {
        this.sharedService.activeTab.next(SideBarTabs.INVENTORY);
      } else if (this.router.url.includes(NavigationScreenRoute.CUSTOMER_CHAT)) {
        this.sharedService.activeTab.next(SideBarTabs.CUSTOMER_CHAT);
      } else if (this.router.url.includes(NavigationScreenRoute.USERS)) {
        this.sharedService.activeTab.next(SideBarTabs.USERS);
      } else if (this.router.url.includes(NavigationScreenRoute.CATEGORY_INVENTORY_TABS)) {
        this.sharedService.activeTab.next(SideBarTabs.CATEGORY_INVENTORY_TABS);
      } else if (this.router.url.includes(NavigationScreenRoute.MANAGE_CATEGORIES)) {
        this.sharedService.activeTab.next(SideBarTabs.MANAGE_CATEGORIES);
      } else if (this.router.url.includes(NavigationScreenRoute.ASK_ME_ANYTHING)) {
        this.sharedService.activeTab.next(SideBarTabs.ASK_ME_ANYTHING);
      } else if (this.router.url.includes(NavigationScreenRoute.CMS)) {
        this.sharedService.activeTab.next(SideBarTabs.CMS);
      } else if (this.router.url.includes(NavigationScreenRoute.MODERATE_REQUESTS)) {
        this.sharedService.activeTab.next(SideBarTabs.MODERATE_REQUESTS);
      } else if (this.router.url.includes(NavigationScreenRoute.ACCOUNT)) {
        this.sharedService.activeTab.next(SideBarTabs.ACCOUNT);
      } else if (this.router.url.includes(NavigationScreenRoute.LOGOUT)) {
        this.sharedService.activeTab.next(SideBarTabs.LOGOUT);
      } else if (this.router.url.includes(NavigationScreenRoute.SUGGESTION_PROMPTS)) {
        this.sharedService.activeTab.next(SideBarTabs.SUGGESTION_PROMPTS);
      }
    }

    /**
     * Set Sidebar Tabs By User Type
     */
    setSidebarTabs (): void {
      this.sideBarTabs = [];
      if (
        this.profileData?.type?.toLowerCase() ===
            AppConstants.UserType.ADMIN.toLowerCase()
      ) {
        this.sideBarTabs = this.adminSidebarTabs;
      } else {
        this.sideBarTabs = this.shopSidebarTabs;
      }
    }

    /**
     * Handles the event when a sidebar tab item is clicked.
     * @param item The SidebarTab object representing the clicked item.
     */
    onTabItemClick (item: SidebarTab): void {
      let activeTab = '';
      this.route.queryParams.subscribe((params) => {
        try {
          if (params.active) {
            activeTab = params.active;
          }
        } catch (err) {
          this.alertService.showAlert('Something went wrong.');
        }
      });

      // Check if current tab is not same as clicked tab
      if (item.key !== activeTab) {
        if (item.subItems) {
          if (this.isSideBarCollapsed) {
            this.isSideBarCollapsed = !this.isSideBarCollapsed;
            // Update Sidebar open/collapsed status
            this.sharedService.sideBarStatus.next(this.isSideBarCollapsed);
          }
          /* Toggle subitems list */
          item.expanded = !item.expanded;
        } else {
          /* Navigate to the screen */
          this.navigate(item);
        }
      }

    }

    /**
     * Navigates to the specified tab when clicked.
     * @param tab The SidebarTab object representing the tab to navigate to.
     */
    navigate (tab: SidebarTab): void {
      this.sharedService.activeTab.next(tab.key as any);
      if (tab.key === SideBarTabs.ADDITIONAL_PROMPTS) {
        // Additional Prompt Modal
        this.getAdditionalPrompt();
      } else {
        this.RedirectHandlerService.redirectTo(tab.key as any);
      }
    }

    // START: Additional Prompt Handling
    // START: Click Listeners
    /**
   * Delete Additional Prompt Click
   */
    deleteAdditionalPromptClick () {
      this.modalService.showModal(
        AlertType.ERROR,
        'Delete',
        'Are you sure, you want to delete the additional prompt?',
        'No',
        () => {
          // Empty Function
        },
        'Yes',
        () => {
          // Positive Button Click
          this.deleteAdditionalPrompt();
        },
      );
    }
    // END: Click Listeners

    /**
   * Handle Additional Prompt Modal Visibility
   */
    handleAdditionalPromptModal () {
      this.showAdditionalPromptModal = !this.showAdditionalPromptModal;
    }

    /**
   * Method to handle input event for fields
   * @param event to fetch input value from event
   * @param type to fetch type of input field
   */
    inputEventHandler (event: any, type: string) {
      switch (type) {
      case this.promptInputFieldKey.ADDITIONAL_PROMPT:
        this.additionalPromptData.prompt = event?.target?.value?.trim();
        break;
      default:
        break;
      }
    }

    // START: Validation Handling
    /**
   * Method to handle additional prompt submission of forms
   */
    validateAdditionalPromptFields (): boolean {
    // Check Prompt Validation
      this.showPromptError.prompt = this.sharedService.validateInput(
        this.additionalPromptData?.prompt,
      );
      if (this.showPromptError.prompt) {
        if (this.sharedService.validateInput(this.additionalPromptData?.prompt)) {
          this.promptErrorMessage.prompt = 'Additional prompt is required';
          return;
        }
      }

      if (
        !this.showPromptError.prompt
      ) {
        return true;
      }
    }
    // START: END Handling

    // START: Additional Prompt API Calls
    /**
   * API Call - Get Additional Prompt
   */
    async getAdditionalPrompt () {
    // Show Loading
      this.sharedService.showLoading();

      // Get API Response
      const apiResponse: NetworkResponse<AdditionalPromptsModel> =
        await this.additionalPromptsService.getAdditionalPrompt(this.profileData?._id);

      // Consume Response
      switch (apiResponse?.responseType) {
      case NetworkResponseType.SUCCESS:
        if (apiResponse?.response?.status) {
          if (apiResponse?.response?.data) {
            this.additionalPromptData = apiResponse?.response?.data;
          }
          if (this.additionalPromptData?.id) {
            this.isUpdatingAdditionalPrompt = true;
          }
          this.handleAdditionalPromptModal();

          // Dismiss Loading
          this.sharedService.dismissLoading();
          return;
        }

        // Show Message
        this.alertService.showAlert(apiResponse?.response?.message);
        break;

      case NetworkResponseType.API_ERROR:
        // Show Message
        this.alertService.showAlert(apiResponse?.response?.message);

        // Check If Authentication Error
        if (isAuthError(apiResponse.code)) {
          // Clear User Data
          await this.sharedService.clearUserData();
          // Notify to Navigate
          this.navigationService.logout();
          return;
        }
        break;

      case NetworkResponseType.UNKNOWN_ERROR:
        // Show Message
        this.alertService.showAlert(apiResponse?.response?.message);
        break;
      }

      if (!this.additionalPromptData?.id) {
        this.initializeAdditionalPromptInputValues();
        this.isUpdatingAdditionalPrompt = false;
      }

      // Handle Additional Prompt Modal
      this.handleAdditionalPromptModal();

      // Dismiss Loading
      this.sharedService.dismissLoading();
    }

    /**
   * API Call - Add Additional Prompt
   */
    async addAdditionalPrompt () {
    // Check input field validations
      if (!this.validateAdditionalPromptFields()) {
        return;
      }

      // Clear Errors
      this.clearAdditionalPromptErrors();

      // Show Loading
      this.sharedService.showLoading();

      // Prepare Body Request
      const json = {
        id: this.profileData?._id,
        prompt: this.additionalPromptData?.prompt,
      };

      // Get API Response
      const apiResponse = await this.additionalPromptsService.createAdditionalPrompt(json);

      // Consume Response
      switch (apiResponse?.responseType) {
      case NetworkResponseType.SUCCESS:
        if (apiResponse?.response?.status) {
          // Dismiss Additional Prompts Modal
          this.handleAdditionalPromptModal();

          // Show Success Modal
          this.modalService.showModal(
            AlertType.SUCCESS,
            'Success',
            'Prompt added successfully.',
            'OK',
          );

          // Dismiss Loading
          this.sharedService.dismissLoading();
          return;
        }

        // Show Message
        this.alertService.showAlert(apiResponse?.response?.message);
        break;

      case NetworkResponseType.API_ERROR:
        // Show Message
        this.alertService.showAlert(apiResponse?.response?.message);

        // Check If Authentication Error
        if (isAuthError(apiResponse.code)) {
          // Clear User Data
          await this.sharedService.clearUserData();
          // Notify to Navigate
          this.navigationService.logout();
          return;
        }
        break;

      case NetworkResponseType.UNKNOWN_ERROR:
        // Show Message
        this.alertService.showAlert(apiResponse?.response?.message);
        break;
      }

      // Dismiss Loading
      this.sharedService.dismissLoading();
    }

    /**
   * API Call - Update Additional Prompt
   */
    async updateAdditionalPrompt (status?: string) {
    // Prepare request body
      let json = {};

      // Check if status has the value
      if (this.sharedService.isNotNullOrEmpty(status)) {
        json = {
          status: status,
        };
      } else {
      // Check input field validations
        if (!this.validateAdditionalPromptFields()) {
          return;
        }

        json = {
          prompt: this.additionalPromptData?.prompt,
        };
      }

      // Clear Errors
      this.clearAdditionalPromptErrors();

      // Show Loading
      this.sharedService.showLoading();

      // Get API Response
      const apiResponse = await this.additionalPromptsService.updateAdditionalPrompt(
        json,
        this.additionalPromptData?.id,
      );

      // Consume Response
      switch (apiResponse?.responseType) {
      case NetworkResponseType.SUCCESS:
        if (apiResponse?.response?.status) {
          if (this.sharedService.isNotNullOrEmpty(status)) {
            this.alertService.showAlert(
              'Prompt status updated successfully.',
              AlertType.SUCCESS,
            );
          } else {
            // Dismiss Additional Prompt Modal
            this.handleAdditionalPromptModal();

            // Show Success Modal
            this.modalService.showModal(
              AlertType.SUCCESS,
              'Success',
              'Prompt updated successfully.',
              'OK',
            );
          }

          // Dismiss Loading
          this.sharedService.dismissLoading();
          return;
        }

        // Show Message
        this.alertService.showAlert(apiResponse?.response?.message);
        break;

      case NetworkResponseType.API_ERROR:
        // Show Message
        this.alertService.showAlert(apiResponse?.response?.message);

        // Check If Authentication Error
        if (isAuthError(apiResponse.code)) {
          // Clear User Data
          await this.sharedService.clearUserData();
          // Notify to Navigate
          this.navigationService.logout();
          return;
        }
        break;

      case NetworkResponseType.UNKNOWN_ERROR:
        // Show Message
        this.alertService.showAlert(apiResponse?.response?.message);
        break;
      }

      // Dismiss Loading
      this.sharedService.dismissLoading();
    }

    /**
   * API Call - Delete Additional Prompt
   */
    async deleteAdditionalPrompt () {
    // Show Loading
      this.sharedService.showLoading();

      // Get API Response
      const apiResponse = await this.additionalPromptsService.deleteAdditionalPrompt(this.additionalPromptData.id);

      // Consume Response
      switch (apiResponse?.responseType) {
      case NetworkResponseType.SUCCESS:
        if (apiResponse?.response?.status) {

          this.initializeAdditionalPromptInputValues();

          this.modalService.showModal(
            AlertType.SUCCESS,
            'Success',
            'Prompt deleted successfully!',
            'OK',
            () => {
              // API Call - Get Additional Prompt
              this.getAdditionalPrompt();
            },
          );

          // Dismiss Loading
          this.sharedService.dismissLoading();
          return;
        }

        // Show Message
        this.alertService.showAlert(apiResponse?.response?.message);
        break;

      case NetworkResponseType.API_ERROR:
        // Show Message
        this.alertService.showAlert(apiResponse?.response?.message);

        // Check If Authentication Error
        if (isAuthError(apiResponse.code)) {
          // Clear User Data
          await this.sharedService.clearUserData();
          // Notify to Navigate
          this.navigationService.logout();
          return;
        }
        break;

      case NetworkResponseType.UNKNOWN_ERROR:
        // Show Message
        this.alertService.showAlert(apiResponse?.response?.message);
        break;
      }

      // Dismiss Loading
      this.sharedService.dismissLoading();
    }

    // END: Additional Prompt API Calls

    // START: Data Initialization
    /**
   * Initialize Additional Prompt Input Values
   */
    initializeAdditionalPromptInputValues () {
      this.additionalPromptData = {
        ...this.additionalPromptData,
        _id: '',
        id: '',
        prompt: '',
      };

      this.clearAdditionalPromptErrors();
    }
    // END: Data Initialization

    // START: Clear Errors
    /**
   * Clear Additional Prompts Errors
   */
    clearAdditionalPromptErrors () {
      this.showPromptError = {
        prompt: false,
      };
    }
  // END: Clear Errors

  // END: Additional Prompt Handling
}
