<div class="main-container" cdkDropList (cdkDropListDropped)="drop($event)">
    <!-- Top Filter, Search, Buttons Container -->
    <div *ngIf="showTopBar" class="filter-container">
        <div
                class="top-bar d-flex"
        >
            <div class="title-container">

                <!-- Delete Button -->
                <div
                        (click)="onDeleteIconClick()"
                        *ngIf="showDeleteIcon && !!selectedElements.length"
                        class="delete-button cursor-pointer"
                >
                    <img alt="" class="icon" src="assets/icons/ic-delete.svg"/>
                </div>

                <!-- Heading Title -->
                <div *ngIf="title" class="heading-text">{{ title }}</div>
            </div>


            <div class="row-container">
                <!-- Search Input Field -->
                <input *ngIf="showSearchInput"
                       id="tableSearch"
                       (change)="searchHandler($event)"
                       autocomplete="off"
                       class="search-input"
                       placeholder="{{ searchPlaceholder }}"
                />

                <!-- Buttons -->
                <div *ngFor="let button of topButtons">
                    <div *ngIf="showTopButtons" class="top-button">
                        <app-button
                                (clickEvent)="triggerClickEvent(button)"
                                [label]="button.text"
                                [type]="button.type"
                                class="top-button"
                                width="150"
                        ></app-button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Root Table Container -->
    <div class="root-table-container">
        <!-- Main Table Container -->
        <div class="table-container">
            <table class="rt-table">
                <thead>
                <tr>
                    <!-- Checkbox Column -->
                    <th *ngIf="showCheckbox" class="checkbox-header">
                        <!-- Checkbox -->
                        <input
                                (change)="checkboxHandler('', 'all')"
                                [checked]="
                  !!(
                    selectedElements.length === tableData.tbody.length &&
                    tableData.tbody.length
                  )
                "
                                class="checkbox cursor-pointer"
                                type="checkbox"
                        />
                    </th>

                    <!-- Dynamic Header Columns -->
                    <ng-container *ngFor="let header of tableData.thead; let index = index">
                        <th [ngClass]="{'picture-header' : tableData.thead[index].type === TableColumnType.PICTURE}">{{ header.title }}</th>
                    </ng-container>
                </tr>
                </thead>

                <!-- Table Body (List Items) -->
                <tbody>
                <tr *ngFor="let body of tableData.tbody; let i = index" cdkDrag [cdkDragData]="body"
                    [cdkDragDisabled]="!isDragEnabled">
                    <!-- Checkbox Container -->
                    <td *ngIf="showCheckbox" class="checkbox-body text-center">
                        <!-- Checkbox -->
                        <input
                                (change)="checkboxHandler(body._id, 'single')"
                                [checked]="selectedElements.includes(body._id)"
                                class="checkbox cursor-pointer"
                                type="checkbox"
                        />
                    </td>

                    <td
                            *ngFor="let header of tableData.thead; let itemIndex = index"
                            [style]="header.type === 'select' ? 'overflow: visible' : '' "
                            class="font-12"
                    >

                        <!-- Picture Container -->
                        <div class="picture-container" *ngIf="header.type === TableColumnType.PICTURE">
                            <img *ngIf="isValidUrl(body[header.id])" class="picture"
                                 src="{{isValidUrl(body[header.id]) ? body[header.id] : 'assets/icons/app-logo.svg'}}"
                                 alt=""/>

                            <div class="picture-container"
                                 [style.background-color]="sharedService.getRandomBackgroundColor(true, false)"
                                 *ngIf="!isValidUrl(body[header.id])">
                                <div class="picture-text">{{ body[header.id] }}</div>
                            </div>

                        </div>


                        <!-- Table Titles (If fontType is primary) -->
                        <div
                                *ngIf="header.type === 'inputText' && header?.fontType"
                                class="row-container"
                        >
                            <div
                                    (click)="onClickActionHandler(header, body)"
                                    [ngClass]="[
                    header.fontType,
                  'table-title',
                    header.isClickable ? 'cursor-pointer' : ''
                  ]"
                            >
                                {{ (isDragEnabled && itemIndex === 0) ? '☰' : '' }} {{ body[header.id] }}
                            </div>
                        </div>

                        <!-- Table Titles (If fontType is not primary) -->
                        <div
                                (click)="onClickActionHandler(header, body)"
                                *ngIf="header.type === 'inputText' && !header?.fontType"
                                [ngClass]="[
                  getClass(header, body),
                  'table-title',
                  header.isClickable ? 'cursor-pointer' : ''
                ]"
                        >
                            {{ (isDragEnabled && itemIndex === 0) ? '☰' : '' }} {{ body[header.id] }}
                        </div>

                        <!-- Table Titles (If fontType is HTML) -->
                        <div
                                *ngIf="header.type === TableColumnType.HTML_CONTENT"
                                [ngClass]="[
                  getClass(header, body),
                  'table-title',
                  header.isClickable ? 'cursor-pointer' : ''
                ]"
                                [innerHTML]="body[header.id]">
                        </div>

                        <!-- Color Box -->
                        <div *ngIf="header.type === TableColumnType.color">
                            <div class="color-box" [ngStyle]="{'background-color': body[header.id]}">
                            </div>
                        </div>

                        <!-- Toggle Switch Container -->
                        <div
                                *ngIf="header.type === 'toggle'"
                                class="form-group position-relative my-2"
                        >
                            <!-- Toggle Switch -->
                            <app-toggle
                                    (clickEvent)="toggleActionHandler($event, i, body)"
                                    [disabled]="header.disabled ?? false"
                                    [isChecked]="body[header.id]"
                            ></app-toggle>
                        </div>

                        <!-- Action Buttons Container -->
                        <div class="d-flex">
                            <!-- Action Buttons List -->
                            <span
                                    *ngFor="let action of header.actions; let j = index"
                                    [ngClass]="action.type"
                                    class="action-button-container">
                                <!-- Icons Action Buttons -->
                                <div class="row-container">
                    <!-- Edit Action Button -->
                    <div
                            (click)="actionEventHandler(action, i, body)"
                            *ngIf="action.type === AppConstants.ButtonType.EDIT"
                            class="action-button cursor-pointer"
                    >
                      <img
                              [src]="'assets/icons/ic-edit-light.svg'"
                              alt=""
                              class="action-icon"
                      />
                    </div>

                                    <!-- Delete Action Button -->
                    <div
                            (click)="actionEventHandler(action, i, body)"
                            *ngIf="action.type === AppConstants.ButtonType.DELETE"
                            class="action-button cursor-pointer"
                    >
                      <img
                              [src]="'assets/icons/ic-delete.svg'"
                              alt=""
                              class="action-icon"
                      />
                    </div>
                  </div>
                            </span>

                            <!-- Action Buttons List -->
                            <span
                                    *ngFor="let actionButton of header.actionButtons; let j = index"
                                    [ngClass]="actionButton.type"
                                    class="action-button"
                                    (click)="actionEventHandler(actionButton, i, body)"
                            >
                                <!-- Action Button -->
                                <app-button
                                        [type]="actionButton.type"
                                        [label]="actionButton.title"
                                ></app-button>
                            </span>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>

            <!-- No Data Found Container -->
            <div *ngIf="!tableData.tbody.length" class="no-data-container">
                <!-- No Data Found CSS -->
                <h3 class="no-data-found-text">No Data Found</h3>
            </div>
        </div>

        <!-- START - PAGINATION -->
        <div class="pagination-container d-flex justify-content-end" *ngIf="!isEndScrollEnabled && showPagination">
            <div class="right-container">
                <img
                        (click)="onPreviousClick()"
                        *ngIf="showPreviousPage"
                        class="arrow"
                        src="/assets/icons/ic-chevron-left-light.svg"
                />
                <span class="active-page">{{ activePage }}</span>
                <img
                        (click)="onNextClick()"
                        *ngIf="showNextPage"
                        class="arrow"
                        src="/assets/icons/ic-chevron-right-light.svg"
                />
            </div>
        </div>
        <!-- END - PAGINATION -->

        <!-- START - END SCROLL PAGINATION -->
        <div class="button-container" *ngIf="isEndScrollEnabled && tableData.tbody.length < totalCount">
            <app-button
                    label="Load more"
                    (clickEvent)="onLoadMoreClick()"
                    type="outline"
                    height="34px"
                    width="max-content"
            />
        </div>
        <!-- END - END SCROLL PAGINATION -->
    </div>
</div>
