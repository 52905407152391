import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  /**
   * Return Location from Selected Google Place
   */
  getLocation (address: Address) {
    address = JSON.parse(JSON.stringify(address));
    const { formatted_address } = address;
    const { name } = address;
    const geo = [ address.geometry.location.lng, address.geometry.location.lat ];
    const coordinate = {
      lat: address.geometry.location.lng,
      long: address.geometry.location.lat,
    };
    const country = address.address_components.find((x) =>
      x.types.includes('country'),
    );
    const locality = address.address_components.find((x) =>
      x.types.includes('locality'),
    );
    const postalCode = address.address_components.find((x) =>
      x.types.includes('postal_code'),
    );
    const streetAddress = address.address_components.find((x) =>
      x.types.includes('street_address'),
    );
    const administrativeAreaLevel1 = address.address_components.find((x) =>
      x.types.includes('administrative_area_level_1'),
    );
    const administrativeAreaLevel2 = address.address_components.find((x) =>
      x.types.includes('administrative_area_level_2'),
    );
    const administrativeAreaLevel3 = address.address_components.find((x) =>
      x.types.includes('administrative_area_level_3'),
    );
    const administrativeAreaLevel4 = address.address_components.find((x) =>
      x.types.includes('administrative_area_level_4'),
    );
    const administrativeAreaLevel5 = address.address_components.find((x) =>
      x.types.includes('administrative_area_level_5'),
    );
    const subLocalityLevel1 = address.address_components.find((x) =>
      x.types.includes('sublocality_level_1'),
    );
    const subLocalityLevel2 = address.address_components.find((x) =>
      x.types.includes('sublocality_level_2'),
    );
    const subLocalityLevel3 = address.address_components.find((x) =>
      x.types.includes('sublocality_level_3'),
    );
    const subLocalityLevel4 = address.address_components.find((x) =>
      x.types.includes('sublocality_level_4'),
    );
    const subLocalityLevel5 = address.address_components.find((x) =>
      x.types.includes('sublocality_level_5'),
    );

    return {
      streetAddress: formatted_address,
      city: name,
      country: country ? country.long_name : '',
      zip: postalCode.long_name,
      coordinate: coordinate,
    };
  }
}
