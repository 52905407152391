<!-- Root Container -->
<div
  #modal
  *ngIf="isOpen"
  class="root-container"
  [id]="id"
  (click)="isCancelable && dismissModal()"
>
  <div class="content-container" (click)="closeDropdowns($event)">
    <!-- Close Button Container -->
    <div class="close-icon-container" (click)="dismissModal()">
      <span>
        <img src="/assets/icons/ic-cross-light.svg" />
      </span>
    </div>

    <!-- Success Icon Container -->
    <div class="success-icon-container text-center" *ngIf="showModalIcon">
      <img src="assets/icons/ic-{{ variant }}.svg" alt="" />
    </div>

    <!-- Modal Title -->
    <div class="title" *ngIf="title">{{ title }}</div>

    <!-- Modal Message -->
    <div class="message" *ngIf="message">{{ message }}</div>

    <!-- Modal Buttons -->
    <div class="row-container">
      <!-- Negative Button -->
      <div class="negative-button" *ngIf="negativeButtonText">
        <app-button
          label="{{ negativeButtonText }}"
          (clickEvent)="onNegativeButtonClick()"
          class="submit"
          type="danger"
        ></app-button>
      </div>

      <!-- Positive Button -->
      <div class="positive-button" *ngIf="positiveButtonText">
        <app-button
          label="{{ positiveButtonText }}"
          (clickEvent)="onPositiveButtonClick()"
          class="submit-button"
          type="primary"
        ></app-button>
      </div>
    </div>
  </div>
</div>
