import { Constants } from '../../network/Constants';
import { HttpMethod } from '../../network/HttpMethod';
import { Injectable } from '@angular/core';
import { NetworkResponse } from '../../network/NetworkResponse';
import { NetworkService } from '../../network/network.service';
import { UploadModel } from 'src/app/models/UploadModel';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  constructor (private networkService: NetworkService) {
    // Empty Constructor
  }

  /**
   * Request to Upload the medias
   *
   * @return Promise with Response
   * */
  upload (formData: FormData): Promise<NetworkResponse<UploadModel>> {
    return this.networkService.apiRequest({
      method: HttpMethod.POST,
      url: 'media/file',
      data: formData,
      contentType: Constants.FORM_DATA,
    });
  }
}
