import { NavigationExtras, Router } from '@angular/router';
import { AppConstants } from 'src/app/constants/AppConstants';
import { Injectable } from '@angular/core';
import { NavigationScreenRoute } from '../../constants/navigation-screen';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private navigationStack: string[] = [];

  constructor (private router: Router) {
    //Empty Constructor
  }

  /**
   * Redirects to a specific tab based on user type.
   * @param userType - Logged In User Type.
   */
  navigateToTab (userType?: string) {
    if (userType?.toLowerCase() === AppConstants.UserType.ADMIN.toLowerCase()) {
      return this.router.navigate([ NavigationScreenRoute.ANALYTICS ]);
    } else {
      return this.router.navigate([ NavigationScreenRoute.INVENTORY ]);
    }
  }

  /**
   * Navigates to a route and adds it to the navigation stack without removing previous routes.
   * @param route The target route path.
   * @param extras Optional navigation parameters.
   */
  public navigateTo (route: string, extras?: NavigationExtras): void {
    if(route) {
      // Add the current route to the navigation stack
      this.navigationStack.push(route);

      // Navigate to the specified route
      this.router.navigate([ route ], extras);
    } else  {
      // Navigate to the specified route
      this.router.navigate([], extras);
    }
  }

  public navigateBack (): void {
    // Pop the current route from the navigation stack
    this.navigationStack.pop();

    // Navigate back to the previous route
    const previousRoute = this.navigationStack.pop();
    if (previousRoute) {
      this.router.navigateByUrl(previousRoute);
    }
  }

  /**
   * Navigate to Login
   * With Logout
   */
  public logout () {
    // Clear User Data from Local Storage
    localStorage.removeItem(AppConstants.LocalStorageKeys.AUTH_TOKEN);
    localStorage.removeItem(AppConstants.LocalStorageKeys.USER_TYPE);
    localStorage.removeItem(AppConstants.LocalStorageKeys.CATEGORY_IDS);
    localStorage.removeItem(AppConstants.LocalStorageKeys.VERIFY_AUTH_TOKEN);
    localStorage.removeItem(AppConstants.LocalStorageKeys.VEC_LOGIC_EMBEDDING_IDS);
    localStorage.removeItem(AppConstants.LocalStorageKeys.CATEGORY_NAME);

    // Navigate to Login Screen
    setTimeout(() => {
      this.router.navigate([ '/' ]);
    }, 10);
  }
}
