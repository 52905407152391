import { Component } from '@angular/core';
import { SharedService } from './services/shared/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
})
export class AppComponent {
  title = 'admin-panel';
  showDemoModal = false;
  private isDarkMode = window.matchMedia('(prefers-color-scheme: dark)')
    .matches;
  presentTheme = 'light';
  // localStorage.getItem("theme") || (this.isDarkMode ? "dark" : "light");

  constructor (private sharedService: SharedService) {
    document.body.classList.toggle('body-dark', this.presentTheme == 'dark');

    // API Call - Get Location Data
    // sharedService.getLocationData();
  }
  triggerModal () {
    this.showDemoModal = !this.showDemoModal;
  }

  handleDemoModal (event: Event) {
    this.showDemoModal = !this.showDemoModal;
  }
}
