<div class="root-container" [ngClass]="{minWidth: !isSideBarCollapsed}">

    <!-- Logo Container -->
    <div
            class="logo-container"
            [ngClass]="{expanded : isSideBarCollapsed}"
            (click)="toggleSidebar()"
    >
        <div class="logo-box">
            <!-- App Logo -->
            <img [src]="'/assets/icons/app-logo.svg'" class="logo" alt=""/>
        </div>
    </div>

    <div class="sidebar">
        <ul class="sidebar-list">
            <li *ngFor="let item of sideBarTabs" class="sidebar-item-container"
                [ngClass]="{'justify-content' : isSideBarCollapsed}">

                <!-- Sidebar Item Container -->
                <div (click)="onTabItemClick(item)" class="sidebar-item"
                     [ngClass]="{ active: isActiveTab(item.key), collapsed: isSideBarCollapsed }">
                    <div class="dropdown-container">
                        <!-- Tab Icon -->
                        <img
                                src="/assets/icons/{{ item?.image }}"
                                height="20"
                                width="20"
                        />

                        <!-- Sidebar Item Title -->
                        <div *ngIf="!isSideBarCollapsed" class="item-title"
                             [ngClass]="{ 'logout-title': item.key === 'logout'}">{{ item.title }}
                        </div>
                    </div>

                    <!-- Tab Arrow Icon -->
                    <img *ngIf="item.subItems && !isSideBarCollapsed"
                         src="/assets/icons/{{item.expanded ? 'ic-chevron-down-light.svg' : 'ic-chevron-right-light.svg' }}"
                         height="20" width="20" style="margin-top: 3px" class="icon"/>
                </div>

                <!-- Sidebar Sub Item Container -->
                <ul *ngIf="item.subItems && item.expanded && !isSideBarCollapsed" class="sidebar-sublist">

                    <li *ngFor="let subItem of item.subItems" class="sidebar-subitem-container">

                        <div (click)="onTabItemClick(subItem)" class="sidebar-subitem"
                             [ngClass]="{ active: isActiveTab(subItem.key), collapsed: isSideBarCollapsed }">
                            <div class="dropdown-container">
                                <!-- Sub Item Icon -->
                                <img
                                        src="/assets/icons/{{ subItem?.image }}"
                                        height="20"
                                        width="20"
                                        [ngStyle]="{ 'margin-top': isSideBarCollapsed ? '0' : '-2px' }"
                                />

                                <!-- Sidebar Sub Item Title -->
                                <div *ngIf="!isSideBarCollapsed" class="subitem-title">{{ subItem.title }}</div>
                            </div>
                            <!-- Tab Arrow Icon -->
                            <img *ngIf="subItem.subItems && !isSideBarCollapsed"
                                 src="/assets/icons/{{subItem.expanded ? 'ic-chevron-down-light.svg' : 'ic-chevron-right-light.svg' }}"
                                 height="20" width="20" style="margin-top: 3px"/>
                        </div>

                        <!-- Sidebar Nested Sub Item Container -->
                        <ul *ngIf="subItem.subItems && subItem.expanded  && !isSideBarCollapsed"
                            class="nested-sidebar-sublist">
                            <li *ngFor="let subSubItem of subItem.subItems" class="nested-sidebar-subitem-container">

                                <div (click)="onTabItemClick(subSubItem)" class="nested-sidebar-subitem"
                                     [ngClass]="{ active: isActiveTab(subSubItem.key), collapsed: isSideBarCollapsed }">
                                    <!-- Sidebar Nested Sub Item Icon -->
                                    <img
                                            src="/assets/icons/{{ subSubItem?.image }}"
                                            height="20"
                                            width="20"
                                            [ngStyle]="{ 'margin-top': isSideBarCollapsed ? '0' : '-2px' }"
                                    />

                                    <!-- Sidebar Nested Sub Item Title -->
                                    <div *ngIf="!isSideBarCollapsed"
                                         class="nested-subitem-title">{{ subSubItem.title }}
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>


                </ul>
            </li>
        </ul>
    </div>

    <!-- Add/Edit/Delete Additional Prompt Modal -->
    <app-modal
            class="modal-container"
            [showModal]="showAdditionalPromptModal"
            [id]="'shopAdditionalPromptModal'"
            (userInput)="handleAdditionalPromptModal(); setInitialActiveTab()"
            [showCloseButton]="true"
            [header]="isUpdatingAdditionalPrompt ? 'Edit Additional Prompt' : 'Add Additional Prompt'"
            [subHeader]="'Fill following details to add new additional prompt'"
    >
        <div
                class="form-container"
        >
            <div class="row">
                <!-- Prompt Input Field -->
                <app-input
                        type="textarea"
                        id="prompt"
                        labelText="Additional Prompt"
                        placeholder="Enter Additional Prompt"
                        [isMandatory]="true"
                        [showErrorMessage]="showPromptError?.prompt"
                        (keyupEvent)="inputEventHandler($event, promptInputFieldKey.ADDITIONAL_PROMPT)"
                        [errorMessage]="promptErrorMessage.prompt"
                        [value]="additionalPromptData?.prompt"
                ></app-input>


                <div class="buttons-root-container" [ngClass]="{'justify-right': !additionalPromptData.id}">
                    <!-- Delete Button -->
                    <div *ngIf="additionalPromptData.prompt && additionalPromptData.id">
                        <app-button
                                type="danger"
                                [label]="'Delete'"
                                class="category-action-button"
                                (clickEvent)="deleteAdditionalPromptClick()"
                        ></app-button>
                    </div>

                    <div class="row-container">
                        <!-- Cancel Button -->
                        <app-button
                                type="danger"
                                [label]="'Cancel'"
                                class="category-action-button"
                                (clickEvent)="handleAdditionalPromptModal(); setInitialActiveTab()"
                        ></app-button>

                        <!-- Submit Button -->
                        <div class="submit-button-container">
                            <app-button
                                    type="primary"
                                    [label]="isUpdatingAdditionalPrompt ? 'Update Additional Prompt' : 'Add Additional Prompt'"
                                    class="category-action-button"
                                    (clickEvent)="isUpdatingAdditionalPrompt ? updateAdditionalPrompt() : addAdditionalPrompt()"
                            ></app-button>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    </app-modal>

</div>
