import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DropDownMenuItem } from '../../../models/DropDownMenuItem';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'app-dropdown-input',
  templateUrl: './dropdown-input.component.html',
  styleUrls: [ './dropdown-input.component.scss' ],
})
export class DropdownInputComponent implements OnInit, OnChanges {
    /** Property to hold label text */
    @Input() labelText: string = '';

    /** Property to hold value to show/hide label text */
    @Input() showLabel: boolean = true;

    /** Property to decide the mandatory input */
    @Input() public isMandatory: boolean = false;

    /** Property to hold the placeholder */
    @Input() public placeholder: string = 'Select Option';

    /** Property to hold error Message in label */
    @Input() errorMessage: string = 'Please Select Option';

    /** Property to display error Message or not */
    @Input() public showErrorMessage: boolean = false;

    /** Property to hold the reversed dropdown */
    @Input() isReversed: boolean = false;

    /** Property to hold the dropdown visibility */
    @Input() showDropDown: boolean = false;

    /** Property to hold status to return object or IDs of selected items */
    @Input() returnObject: boolean = false;

    /** Property to hold option list in {_id: string, title: string,isDisabled: boolean } object format */
    @Input() optionList: DropDownMenuItem[] = [];

    /** Property to hold original option list in {_id: string, title: string,isDisabled: boolean } object format */
    @Input() originalList: DropDownMenuItem[] = [];

    /** Property to hold label text */
    @Input() value: string = '';

    /** Property to emit selected option to parent component */
    @Output() clickEvent: EventEmitter<any> = new EventEmitter<any>();

    /** Property to hold to enable search */
    @Input() enableSearch: boolean = false;

    /** Property to hold the id */
    @Input() id: string = 'dropdown';

    /** Property to hold the theme */
    @Input() theme: string = 'light';

    /** Property to make input readonly */
    @Input() readonly: boolean = false;

    /** Property to hold the value for single selection */
    @Input() isSingleSelection: boolean = true;

    /** Property to hold the margin-top of the input field */
    @Input() public marginTop: string = '12';

    /** Property to hold the margin-bottom of the input field */
    @Input() public marginBottom: string = '0';

    /** Primary Constructor */
    constructor (private sharedService: SharedService) {
      // Empty
    }

    /**
     * On Int Method
     */
    ngOnInit () {
      if (document?.body?.classList?.contains('body-dark')) {
        this.theme = 'dark';
      } else {
        this.theme = 'light';
      }

      // Initialize the selected options
      this.initializeComponent();
    }

    /**
     * On Option Click
     */
    onOptionClick (event: any) {
      // If Item is Disabled Than Return
      if (event?.isDisabled) {
        return;
      }

      // Find the clicked item in the filteredList
      const clickedItem = this.optionList.find(
        (item) => item?._id === event?._id,
      );

      // If the clicked item is found
      if (clickedItem) {


        if (this.isSingleSelection) {
          this.originalList.forEach((item): boolean => (item.isSelected = false));

          // Toggle the isSelected property of the clicked item
          clickedItem.isSelected = !clickedItem.isSelected;

          const originalItem = this.originalList.find(
            (item): boolean => item?._id === clickedItem?._id,
          );
          if (originalItem) {
            originalItem.isSelected = clickedItem?.isSelected;
          }

          this.onDoneClick();
        } else {
          // Toggle the isSelected property of the clicked item
          clickedItem.isSelected = !clickedItem.isSelected;

          const originalItem = this.originalList.find(
            (item): boolean => item?._id === clickedItem?._id,
          );
          if (originalItem) {
            originalItem.isSelected = clickedItem?.isSelected;
          }
        }

      }

      // Format Selected Sub-Categories Names
      this.value = this.originalList
        .filter((item) => item?.isSelected)
        .map((item) => item?.title)
        .join(', ');
    }

    /**
     * Function to handle the search event
     */
    searchEventHandler (event: any) {
      const searchText: string = event?.target?.value;
      if (!this.sharedService.isNotNullOrEmpty(searchText)) {
        this.optionList = [ ...this.originalList ];
      } else {
        this.optionList = this.originalList.filter((item) =>
          item?.title?.toLowerCase().includes(searchText?.toLowerCase()),
        );
      }
    }

    /**
     * Function to toggle the dropdown
     */
    toggleDropdown (event: any) {
      if (!this.readonly) {
        event.stopPropagation();

        // Show/Dismiss Dropdown
        this.showDropDown = !this.showDropDown;
      }
    }

    /**
     * Done Button Click Event
     */
    onDoneClick () {
      // Hide Dropdown
      this.showDropDown = false;

      // Get the IDs of selected items
      const selectedIds = this.originalList
        .filter((item) => item?.isSelected)
        .map((item) => item?._id);
      const selectedItems = this.originalList
        .filter((item) => item?.isSelected);

      // Emit Selected Option
      this.clickEvent.emit(this.returnObject ? selectedItems : selectedIds);

      // Reset Errors
      this.errorMessage = '';
      this.showErrorMessage = false;
    }

    /**
     * Lifecycle hook to handle changes to input properties
     */
    ngOnChanges (changes: SimpleChanges) {
      if (changes.originalList) {
        this.initializeComponent();
      }
    }

    /**
     * Method to initialize the component
     */
    private initializeComponent () {
      if (this.originalList && this.originalList.length) {
        this.optionList = [ ...this.originalList ];
        this.value = this.originalList
          .filter((item) => item?.isSelected)
          .map((item) => item?.title)
          .join(', ');
      }
    }
}
