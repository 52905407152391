<!-- Root Container -->
<div
    #modal
    (click)="action(false)"
    [id]="id"
    aria-hidden="true"
    class="modal fade"
    tabindex="-1"
>
    <!-- Modal Container -->
    <div class="modal-dialog" role="document">
        <!-- Modal Content Container -->
        <div (click)="closeDropdowns($event)" class="modal-content">
            <!-- Success Icon Container -->
            <div *ngIf="showSuccessImage" class="success-icon-container text-center">
                <img alt="" src="assets/icons/ic-success.svg"/>
            </div>

            <!-- Header, Close Button Container -->
            <div class="modal-header p-0">
                <div class="header-container d-flex">
                    <!-- Header Text -->
                    <span *ngIf="showHeader" class="heading">{{ header }}</span>

                    <!-- Sub-Heading -->
                    <span *ngIf="{subHeader}" class="sub-heading">{{ subHeader }}</span>
                </div>

                <!-- Close Button Container -->
                <div (click)="action(false)" class="close-icon-container">
          <span>
            <img src="/assets/icons/ic-cross-light.svg"/>
          </span>
                </div>
            </div>

            <!-- Modal Body Container -->
            <div class="modal-body p-0">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
