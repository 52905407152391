<div class="dropdown-container position-relative"
     [style.margin-top]="marginTop + 'px'"
     [style.margin-bottom]="marginBottom + 'px'">

    <!-- Label With Error -->
    <label
            class="label lp position-relative mb-1"
            *ngIf="showLabel && labelText"
            [ngClass]="{ 'required-text': showErrorMessage }"
    >{{
            showErrorMessage ? errorMessage : labelText
        }}<span class="mandatory" *ngIf="showLabel && isMandatory"> *</span></label
    >

    <!-- Input Box Container -->
    <div
            class="input-box-container position-relative p-0"
            (click)="toggleDropdown($event)"
            [id]="id"
    >
        <!-- Input Box -->
        <div
                class="input-box"
                [ngClass]="{ 'required-input-box': showErrorMessage }"
        >
            <!-- Input Text -->
            <p *ngIf="value">{{ value }}</p>

            <!-- Placeholder Text -->
            <p class="placeholder-text" *ngIf="!value">
                {{ placeholder }}
            </p>
        </div>

        <!-- Dropdown Arrow Icon -->
        <img
                class="drop-down-arrow"
                [ngClass]="{ 'drop-down-arrow-active': showDropDown }"
                src="assets/icons/ic-chevron-down-{{ theme }}.svg"
                alt=""
        />
    </div>

    <!-- Category Dropdown Container -->
    <div class="advanced-dropdown-container" *ngIf="showDropDown">

        <!-- Back Button, Search Bar Container -->
        <div class="row-container">

            <!-- back Icon -->
            <div class="icon-container" *ngIf="selectedOptionId && showBackIconButton">
                <img class="back-icon" src="/assets/icons/ic-chevron-left-light.svg"
                     (click)="onBackClick()"/>
            </div>

            <!-- Search Input Container -->
            <div
                    class="search-input-container"
                    (click)="$event.stopPropagation()"
                    *ngIf="enableSearch"
            >
                <!-- Search Input Field -->
                <input
                        type="text"
                        (keyup)="searchEventHandler($event)"
                        placeholder="Search here..."
                        class="search-field"
                />
                <img class="search-icon" src="/assets/icons/ic-search-light.svg" (click)="onSearchClick()"/>
            </div>
        </div>

        <!-- Dropdown Options Container -->
        <div class="dropdown-options-container">
            <!-- Selected Options List -->
            <div
                    (click)="onOptionClick(item)"
                    class="option-item-box justify-content-between"
                    *ngFor="let item of optionList; let i = index"
                    [ngClass]="{'active-option-item-box' : item?.title === selectedOptionId}"
            >
                <!-- Item Title Text -->
                <span>{{
                        item?.title
                    }}</span>

                <img *ngIf="item.showArrow"
                     src="/assets/icons/ic-chevron-right-light.svg"/>
            </div>
        </div>

        <!-- Load More Button Container -->
        <div class="load-more-container" *ngIf="this.originalList?.length < totalCount">
            <app-button
                    label="Load more"
                    (clickEvent)="onLoadMoreClick()"
                    type="text"
                    height="34px"
            />
        </div>

        <!-- No Data Container -->
        <div class="no-data-found" *ngIf="!this.optionList?.length">
            No Data Found
        </div>

    </div>
</div>
