import { AlertType } from 'src/app/constants/AppConstants';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  currentConfigSubject: Subject<{
    open?: boolean;
    variant?: AlertType;
    title?: string;
    message?: string;
    positiveButtonText?: string;
    positiveButtonClick?: () => void;
    negativeButtonText?: string;
    negativeButtonClick?: () => void;
    isCancelable?: boolean;
  }> = new Subject();

  /**
   * Displays a modal dialog with customizable options.
   *
   * @param variant Type of message (e.g., AlertType.ERROR).
   * @param title Modal title.
   * @param message Message to display.
   * @param positiveButtonText Text for positive button.
   * @param positiveButtonClick Callback for positive button.
   * @param negativeButtonText Text for negative button.
   * @param negativeButtonClick Callback for negative button.
   * @param isCancelable Indicates if modal can be closed without button press.
   */
  showModal (
    variant?: AlertType,
    title?: string,
    message?: string,
    positiveButtonText?: string,
    positiveButtonClick?: () => void,
    negativeButtonText?: string,
    negativeButtonClick?: () => void,
    isCancelable?: boolean,
  ): void {
    this.currentConfigSubject.next({
      open: true,
      variant: variant ?? AlertType.ERROR,
      title: title ?? 'Error',
      message: message ?? 'Something went wrong',
      positiveButtonText: positiveButtonText ?? 'Yes',
      positiveButtonClick: positiveButtonClick,
      negativeButtonText: negativeButtonText,
      negativeButtonClick: negativeButtonClick,
      isCancelable: isCancelable ?? true,
    });
  }

  /**
   * Dismiss the message modal.
   */
  dismissModal (): void {
    this.currentConfigSubject.next({
      open: false,
    });
  }
}
