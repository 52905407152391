<div class="input-container"
     [style.margin-top]="marginTop + 'px'"
     [style.margin-bottom]="marginBottom + 'px'">

    <!-- Label With Error -->
    <label
            class="label lp position-relative mb-1"
            *ngIf="showLabel && labelText"
            [ngClass]="{ 'required-text': showErrorMessage }"
    >{{
            showErrorMessage ? errorMessage : labelText
        }}<span class="mandatory" *ngIf="showLabel && isMandatory"> *</span></label
    >

    <div
            class="position-relative d-flex field-container"
            [ngClass]="{ 'required': showErrorMessage }"
    >
        <!-- Dropdown -->
        <div
                class="input-box left-border-radius d-flex"
                [ngClass]="{ readonly: readonly }"
                (click)="
        showCountryDropdown = !showCountryDropdown; $event.stopPropagation()
      "
        >
            <!-- Loader -->
            <div *ngIf="isLoaderVisible" class="loader-container position-absolute">
                <img src="assets/icons/ic-button-loader.svg" alt="loader" height="24" width="24"/>
            </div>

            <!-- Selected Country Flag -->
            <img
                    *ngIf="!isLoaderVisible && selectedCountry?.flag"
                    [src]="selectedCountry?.flag"
                    alt=""
                    class="flag-image"
            />

            <!-- Selected Country Code -->
            <span *ngIf="!isLoaderVisible">{{ selectedCountry?.dial_code }}</span>
        </div>

        <!-- Input Field -->
        <input
                class="input-box right-border-radius"
                [id]="id"
                name="{{ name }}"
                [style.height]="height + 'px'"
                (keyup)="inputKeyEventHandler($event)"
                (change)="inputValueHandler($event)"
                [readonly]="readonly"
                [required]="required"
                [type]="type"
                (input)="phoneNumberValue($event)"
                [value]="getMobileValue()"
                (keydown)="sharedService.checkInput($event, 'number', 15)"
                [placeholder]="placeholder"
        />
    </div>

    <!-- Dropdown Container -->
    <div
            *ngIf="showCountryDropdown && isDropdownClickable"
            class="country-dropdown-container"
            (click)="$event.stopPropagation()"
    >
        <div class="position-relative">
            <input
                    class="search-field"
                    autocomplete="off"
                    type="text"
                    [(ngModel)]="searchText"
                    (keyup)="searchEvent()"
                    placeholder="Search..."
            />
            <img
                    src="assets/icons/ic-search-{{ theme }}.svg"
                    alt=""
                    class="position-absolute search-icon"
            />
        </div>
        <div class="country-dropdown">
            <div
                    *ngFor="let country of countries"
                    class="country d-flex justify-content-between"
                    (click)="onCountrySelect(country)"
            >
        <span
                [ngClass]="{
            'selected-option': country.dial_code === selectedCountry.dial_code
          }"
        >({{ country.dial_code }}) {{ country.name }}</span
        >
                <img
                        src="assets/icons/ic-check.svg"
                        alt=""
                        *ngIf="country.dial_code === selectedCountry.dial_code"
                />
            </div>
        </div>
    </div>
</div>
