import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'app-country-dropdown',
  templateUrl: './country-dropdown.component.html',
  styleUrls: [ './country-dropdown.component.scss' ],
})
export class CountryDropdownComponent implements OnInit, OnChanges {
    /** Property to hold the theme */
    @Input() public theme: string = 'light';

    /** Property to hold input name for data binding */
    @Input() public name: string = 'name';

    /** Property to hold the default height of input field */
    @Input() public height: string = '48';

    /** Property to hold the margin-top of the input field */
    @Input() public marginTop: string = '12';

    /** Property to hold the margin-bottom of the input field */
    @Input() public marginBottom: string = '0';

    /** Property to hold the input id */
    @Input() public id: string = '';

    /** Property to hold the type of input */
    @Input() public type: string = 'number';

    /** Property to hold loader visibility */
    @Input() isLoaderVisible: boolean = false;

    /** Property to hold input label text */
    @Input() labelText: string = 'Title';

    /** Property to hold to show label text or not */
    @Input() public showLabel: boolean = true;

    /** Property to hold the placeholder */
    @Input() public placeholder: string = '';

    /** Property to hold if the input is mandatory */
    @Input() public isMandatory: boolean = true;

    /** Property hold phone prefix value */
    @Input() prefix: string = '';

    /** Property hold input phone number */
    @Input() phoneNumber: string = '';

    /** Property to hold to show dropdown or not */
    @Input() showCountryDropdown: boolean = false;

    /** Property to hold the input error message */
    @Input() public errorMessage: string = '';

    /** Property to hold the input enable/disable value */
    @Input() public disabled: boolean = false;

    /** Property to hold to show error message */
    @Input() showErrorMessage: boolean = false;

    /** Property to hold search text of dropdown */
    @Input() searchText: string = '';

    /** Property to emit input change event */
    @Output() inputChange: EventEmitter<any> = new EventEmitter<any>();

    /** Property to hold default selected country in dropdown */
    selectedCountry = {
      dial_code: 'IE',
      flag: 'assets/statics/flags/ie.svg',
      characters: '8',
    };

    /** Property to hold all countries list */
    @Input() countries: any = [];

    /** Property to hold searched countries list */
    @Input() searchedCountries: any = [];

    /** Property to handle click event on document */
    @HostListener('document:click', [ '$event' ]) onDocumentClick () {
      this.showCountryDropdown = false;
    }

    /** Property to emit country click event */
    @Output() clickEvent: EventEmitter<any> = new EventEmitter<any>();

    /** Property to emit country click event */
    @Output() countryCodeEvent: EventEmitter<any> = new EventEmitter<any>();

    /** Property to emit input key up event */
    @Output() keyupEvent: EventEmitter<any> = new EventEmitter<any>();

    /** Property to hold input value */
    @Input() public value: string = '';

    /** Property to hold enable/disable value of country code dropdown */
    @Input() isDropdownClickable: boolean = true;

    /** Property to hold if the input is required */
    @Input() public required: boolean = false;

    /** Property to make input readonly */
    @Input() readonly: boolean = false;

    /** Property to get country phone number input value */
    getMobileValue () {
      if (this.readonly) {
        return this.value;
      }
      return this.phoneNumber;
    }

    constructor (public sharedService: SharedService) {
      this.getSelectedCountry();
    }

    /**
     * Get Selected Country Data
     */
    async getSelectedCountry () {
      // Show Loader
      this.isLoaderVisible = true;

      await this.sharedService
        .getAllCountries()
        .then((countries) => {
          for (const country of countries) {
            this.countries = [
              ...this.countries,
              {
                ...country,
                flag: `assets/statics/flags/${ country.code.toLowerCase() }.svg`,
              },
            ];
            this.searchedCountries = [
              ...this.countries,
              {
                ...country,
                flag: `assets/statics/flags/${ country.code.toLowerCase() }.svg`,
              },
            ];
          }

          this.selectedCountry = this.searchedCountries.find((country: any) =>
            (country.code || '').match(
              new RegExp(this.selectedCountry?.dial_code, 'gi'),
            ),
          );

          this.countries = this.countries.filter(
            (country: any) => country !== this.selectedCountry,
          );

          this.countries = [ this.selectedCountry, ...this.countries ];

          // Emit Selected Country Code
          this.clickEvent.emit(this.selectedCountry?.dial_code);
          this.countryCodeEvent.emit(this.selectedCountry?.characters);

          // Dismiss Loader
          setTimeout(() => {
            this.isLoaderVisible = false;
          }, 1000);
        })
        .catch((error) => {
          console.error('Error getting static text:', error);
        });
    }

    /**
     * Method to handle search event on country dropdown
     */
    public searchEvent () {
      this.countries = this.searchedCountries.filter((country: any) => {
        const nameMatch = (country.name || '')
          .toLowerCase()
          .includes(this.searchText.toLowerCase());
        const codeMatch =
                (country.dial_code || '').includes(this.searchText) ||
                (country.dial_code || '').includes(`+${ this.searchText }`);
        return nameMatch || codeMatch;
      });
    }

    /**
     * Method to format the phone number
     * @param event keyup input value
     */
    phoneNumberValue (event: any) {
      this.phoneNumber = event.target.value;
    }

    /**
     * On Country item Select
     * @param country selected country
     */
    onCountrySelect (country: any) {
      this.selectedCountry = country;
      this.countries = this.countries?.filter((c: any) => c !== country);
      this.countries.sort((a: any, b: any) => a?.name.localeCompare(b?.name));
      this.countries = [ country, ...this.countries ];
      this.showCountryDropdown = !this.showCountryDropdown;
      this.clickEvent.emit(this.selectedCountry?.dial_code);
      this.countryCodeEvent.emit(this.selectedCountry?.characters);
    }

    /**
     * Emit Input Keyup Event
     */
    inputKeyEventHandler (event: any) {
      this.keyupEvent.emit(event);
    }

    /**
     * Property to emit onValue change event
     */
    public inputValueHandler (event: any, type?: string) {
      // Emit Input Value
      this.inputChange.emit(event);
    }

    /**
     * On Init Method
     */
    ngOnInit (): void {
      // Set Default Prefix
      if (this.prefix) {
        this.selectedCountry = this.countries?.find(
          (country: any) => country?.dial_code === this.prefix,
        );
      }
    }

    /**
     * On Changes Method
     */
    ngOnChanges (): void {
      // Set Default Prefix
      if (this.prefix) {
        this.selectedCountry = this.countries?.find(
          (country: any) => country?.dial_code === this.prefix,
        );
      }
    }
}
