<div
  class="checkbox-container align-items-center"
  [ngClass]="{ 'd-flex': label }"
>
  <input
    type="checkbox"
    [name]="name"
    [id]="id"
    (change)="onCheckboxChange()"
    class="checkbox"
    (click)="$event.stopPropagation()"
    [checked]="checked"
  />
  <label
    *ngIf="label"
    for="{{ id }}"
    class="checkbox-label"
    (Click)="onCheckboxChange()"
    >{{ label }}</label
  >
</div>
