import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AlertService } from '../../../services/alert/alert.service';
import { AlertType } from '../../../constants/AppConstants';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MediaService } from '../../../services/api-service/media/media.service';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { NetworkResponseType } from '../../../services/network/NetworkResponse';
import { SharedService } from 'src/app/services/shared/shared.service';
import { isAuthError } from '../../../services/network/Extensions';

@Component({
  selector: 'app-html-editor-input',
  templateUrl: './html-editor-input.component.html',
  styleUrls: [ './html-editor-input.component.scss' ],
})
export class HtmlEditorInputComponent implements OnInit {
    @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
    @ViewChild('editor') editor: CKEditorComponent;

    /** Property to hold the theme */
    @Input() public theme: string = 'light';

    /** Property to hold editor */
    @Input() public editorInstance: any = ClassicEditor;

    /** Property to hold input name for data binding */
    @Input() public name: string = 'name';

    /** Property to hold the default height of input field */
    @Input() public height: string = '48';

    /** Property to hold the margin-top of the input field */
    @Input() public marginTop: string = '12';

    /** Property to hold the margin-bottom of the input field */
    @Input() public marginBottom: string = '0';

    /** Property to hold the input id */
    @Input() public id: string = '';

    /** Property to hold the type of input */
    @Input() public type: string = 'htmlEditor';

    /** Property to hold input label text */
    @Input() labelText: string = '';

    /** Property to hold to show label text or not */
    @Input() public showLabel: boolean = true;

    /** Property to hold the placeholder */
    @Input() public placeholder: string = '';

    /** Property to hold the input error message */
    @Input() public errorMessage: string = '';

    /** Property to hold the input enable/disable value */
    @Input() public disabled: boolean = false;

    /** Property to hold if the input is required */
    @Input() public required: boolean = false;

    /** Property to hold if the input is mandatory */
    @Input() public isMandatory: boolean = true;

    /** Property to hold to show error message */
    @Input() showErrorMessage: boolean = false;

    /** Property to hold input value */
    @Input() public value: string = '';

    /** Property to hold the input info message */
    @Input() inputInfoMessage: string = '';

    // START: Input Events
    /** Property to emit input change event */
    @Output() inputChange: EventEmitter<any> = new EventEmitter<any>();

    /** Property to hold uploaded image url */
    @Output() uploadedImage: string = '';

    // END: Input Events

    constructor (
        public sharedService: SharedService,
        public alertService: AlertService,
        public mediaService: MediaService,
        public navigationService: NavigationService,
    ) {
      // Empty Constructor
    }

    ngOnInit (): void {
      if (document.body.classList.contains('body-dark')) {
        this.theme = 'dark';
      } else {
        this.theme = 'light';
      }
    }

    /**
     * Property to emit onValue change event
     */
    public inputValueHandler (event: any) {
      // Emit Input Value
      console.log('HELLO_001', event?.trim());
      this.inputChange.emit(event);
    }

    public editorConfig = {
      placeholder: 'Enter your text here...',
      toolbar: [
        'undo', 'redo',
        '|', 'heading',
        '|', 'bold', 'italic',
        '|', 'link', 'blockquote',
        '|', 'insertTable', 'bulletedList', 'numberedList',
      ],
    };
    public editorContent: string = '';

    onReady (editor: any) {
      editor.editing.view.document.on('keyup', (event: any, domEvent: KeyboardEvent) => {
        this.inputValueHandler(domEvent);
      });
    }

    onEditorReady (editor: ClassicEditor) {
      this.editorInstance = editor;
    }

    /**
     * Upload Image Picture
     */
    async uploadMedia (event: any, type?: string | null | undefined) {
      const inputElement = event.target as HTMLInputElement;
      if (!inputElement.files && !(inputElement.files.length > 0)) {
        return this.alertService.showAlert('Please select an image');
      }

      // Prepare Form Data
      const file = event.target.files[0];

      // Check the file type
      if (
        file.type !== 'image/jpeg' &&
            file.type !== 'image/jpg' &&
            file.type !== 'image/png'
      ) {
        return this.alertService.showAlert(
          'Invalid file type. Only .jpg .jpeg & png files are allowed.',
        );
      }

      const formData = new FormData();
      formData.append('type', type ?? '*/*');
      formData.append('file', file, file.name);

      // Get File Size
      const fileSize = file.size;
      const fileSizeReadable = Math.round(fileSize / 1024);

      // Check File Size
      if (fileSizeReadable > 20480) {
        this.alertService.showAlert(
          'File too Big, please select a file less than 20MB',
        );
        return;
      }

      // Show Loading
      this.sharedService.showLoading();

      // Get API Response
      const apiResponse = await this.mediaService.upload(formData);

      // Consume Response
      switch (apiResponse.responseType) {
      case NetworkResponseType.SUCCESS:
        if (apiResponse?.response?.success) {
          // Check Message User Data
          if (apiResponse?.response?.data !== null) {
            this.uploadedImage = apiResponse?.response?.data?.url;

            /* eslint-disable max-depth */
            if (this.uploadedImage) {
              this.insertUploadedImage();
            }
          }

          // Dismiss Loading
          this.sharedService.dismissLoading();
          return;
        }

        // Show Message
        this.alertService.showAlert(
          apiResponse.response.message,
          AlertType.SUCCESS,
        );
        break;

      case NetworkResponseType.API_ERROR:
        // Show Message
        this.alertService.showAlert(apiResponse.response.message);

        // Check If Authentication Error
        if (isAuthError(apiResponse.code)) {
          // Clear User Data
          await this.sharedService.clearUserData();
          // Notify to Navigate
          this.navigationService.logout();
          return;
        }
        break;

      case NetworkResponseType.UNKNOWN_ERROR:
        // Show Message
        this.alertService.showAlert(apiResponse.response.message);
        break;
      }

      // Dismiss Loading
      this.sharedService.dismissLoading();
    }

    /**
     * Insert Uploaded Image in Editor
     */
    insertUploadedImage () {
      if (this.editorInstance) {
        const { model } = this.editorInstance;

        this.editorInstance.model.change((writer) => {
          const root = model.document.getRoot();
          const position = writer.createPositionAt(root, root.childCount);

          // Create an image element with specific dimensions
          const imageElement = writer.createElement('imageBlock', {
            src: this.uploadedImage,
          });

          writer.insert(imageElement, position);
        });

        // Clear uploaded image
        this.uploadedImage = '';
      }

    }
}
