import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CategoryType } from '../../../constants/ApiConstants';
import { DropDownMenuItem } from '../../../models/DropDownMenuItem';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'app-paginated-dropdown-input',
  templateUrl: './paginated-dropdown-input.component.html',
  styleUrls: [ './paginated-dropdown-input.component.scss' ],
})
export class PaginatedDropdownInputComponent implements OnInit {
    /** Property to hold label text */
    @Input() labelText: string = '';

    /** Property to hold value to show/hide label text */
    @Input() showLabel: boolean = true;

    /** Property to decide the mandatory input */
    @Input() public isMandatory: boolean = false;

    /** Property to hold the placeholder */
    @Input() public placeholder: string = 'Select Option';

    /** Property to hold error Message in label */
    @Input() errorMessage: string = 'Please Select Option';

    /** Property to display error Message or not */
    @Input() public showErrorMessage: boolean = false;

    /** Property to hold the reversed dropdown */
    @Input() isReversed: boolean = false;

    /** Property to hold the dropdown visibility */
    @Input() showDropDown: boolean = false;

    /** Property to hold status to return object or IDs of selected items */
    @Input() returnObject: boolean = false;

    /** Property to hold option list in {_id: string, title: string,isDisabled: boolean } object format */
    @Input() optionList: DropDownMenuItem[] = [];

    /** Property to hold original option list in {_id: string, title: string,isDisabled: boolean } object format */
    @Input() originalList: DropDownMenuItem[] = [];

    /** Property to hold label text */
    @Input() value: string = '';

    /** Property to emit selected option to parent component */
    @Output() clickEvent: EventEmitter<any> = new EventEmitter<any>();

    /** Property to hold to enable search */
    @Input() enableSearch: boolean = false;

    /** Property to hold the id */
    @Input() id: string = 'dropdown';

    /** Property to hold the theme */
    @Input() theme: string = 'light';

    /** Property to make input readonly */
    @Input() readonly: boolean = false;

    /** Property to hold the margin-top of the input field */
    @Input() public marginTop: string = '12';

    /** Property to hold the margin-bottom of the input field */
    @Input() public marginBottom: string = '0';

    /** Property to hold selected option ID */
    @Input() selectedOptionId: string = '';

    /** Property to hold back icon button status */
    @Input() showBackIconButton: boolean = true;

    /** Property to hold totalCount comes from API */
    @Input() totalCount: number = 0;

    /** Property to hold search text value */
    @Input() searchText: string = '';

    /** Property to hold API search handling status */
    @Input() isApiSearch: boolean = false;

    /** Property to emit the load more click event */
    @Output() loadMoreClickEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    /** Property to emit the back icon button click event */
    @Output() backClickEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    /** Property to emit dropdown visibility event */
    @Output() showDropDownEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    /** Property to emit the search event */
    @Output() searchEvent: EventEmitter<string> = new EventEmitter<string>();

    /** Primary Constructor */
    constructor (private sharedService: SharedService) {
      // Empty
    }

    /**
     * On Int Method
     */
    ngOnInit () {
      if (document?.body?.classList?.contains('body-dark')) {
        this.theme = 'dark';
      } else {
        this.theme = 'light';
      }
    }

    /**
     * On Option Click
     */
    onOptionClick (event: any) {

      // If Item is Disabled Than Return
      if (event?.isDisabled) {
        return;
      }

      // Emit Selected Option
      this.clickEvent.emit(event);

      // Reset Errors
      this.errorMessage = '';
      this.showErrorMessage = false;
    }

    /**
     * Function to handle the search event
     */
    searchEventHandler (event: any) {
      this.searchText = event?.target?.value;
      if (!this.isApiSearch) {
        if (!this.sharedService.isNotNullOrEmpty(this.searchText)) {
          this.optionList = [ ...this.originalList ];
        } else {
          this.optionList = this.originalList.filter((item) =>
            item?.title?.toLowerCase().includes(this.searchText?.toLowerCase()),
          );
        }
        if (this.optionList.length > 0) {
          this.loadMoreClickEvent;
        }
      }
    }

    /**
     * Function to toggle the dropdown
     */
    toggleDropdown (event: any) {
      if (!this.readonly) {
        event.stopPropagation();
        // Emit Dropdown Event
        this.showDropDownEvent.emit(!this.showDropDown);
      }
    }

    /**
     * Function to emit API search event
     */
    onSearchClick () {
      if (this.isApiSearch) {
        this.searchEvent.emit(this.searchText ? this.searchText?.toLowerCase() : '');
      }
    }

    /**
     * Back Icon Button Click Event
     */
    onBackClick () {
      this.backClickEvent.emit(true);
    }

    /**
     * Load More Button Click Event
     */
    onLoadMoreClick () {
      this.loadMoreClickEvent.emit(true);
    }

    protected readonly CategoryType = CategoryType;
}
