import * as PlotlyJS from 'plotly.js-dist-min';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { GooglePlacesDirective } from './google-places.directive';
import { PlotlyModule } from 'angular-plotly.js';
import { SharedModule } from './modules/shared/shared.module';

PlotlyModule.plotlyjs = PlotlyJS;

@NgModule({
  declarations: [
    AppComponent,
    GooglePlacesDirective,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
    BrowserAnimationsModule,
    PlotlyModule,
  ],
  providers: [],
  bootstrap: [ AppComponent ],
  schemas : [ CUSTOM_ELEMENTS_SCHEMA,  NO_ERRORS_SCHEMA ],
})
export class AppModule { }
