import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AppConstants } from 'src/app/constants/AppConstants';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: [ './pagination.component.scss' ],
})
export class PaginationComponent implements OnInit {
  /** Active page count */
  @Input() activePage = 1;

  /** Total entries count */
  @Input() totalCount = 0;

  /** Property to emit current page number */
  @Output() currentPage = new EventEmitter<any>();

  /** Pagination Keys */
  paginationKey = AppConstants.PaginationKey;

  constructor (private sharedService: SharedService) {
    //Empty Constructor
  }

  ngOnInit () {
    this.sharedService.refreshRoute.subscribe((route: string) => {
      if (route) {
        // Set active page number
        this.activePage = 1;
      }
    });
  }

  /**
   * Previous Page Icon Click
   * @param pageKey Page Key previous-page
   */
  onPreviousClick () {
    // Check if active page is greater than 1 then user can click on previous page icons
    if (this.activePage > 1) {
      this.activePage--;
    }

    this.currentPage.emit({ page: this.activePage, event: 'previous' });
  }

  /**
   * Next Page Icon Click
   * @param pageKey Page Key next-page
   */
  onNextClick () {
    // Check if active page is greater than 1 then user can click on previous page icons
    if (this.activePage < this.totalCount) {
      this.activePage++;
    }
    this.currentPage.emit({ page: this.activePage, event: 'next' });
  }
}
