import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { AlertType } from 'src/app/constants/AppConstants';
import { DropdownService } from 'src/app/services/dropdown/dropdown.service';
import { ModalService } from 'src/app/services/modal/modal.service';
declare let $: any;

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: [ './message-modal.component.scss' ],
})
export class MessageModalComponent implements OnDestroy {
  /** Property to hold modal theme */
  @Input() theme = 'light';

  /** Property to store modal ID */
  @Input() id = 'modal';

  /** Property to hold modal title */
  @Input() title = '';

  /** Property to hold modal message */
  @Input() message = '';

  /** Property to hold modal positive button text */
  @Input() positiveButtonText = '';

  /** Property to hold modal negative button text */
  @Input() negativeButtonText = '';

  /** Property to hold modal close button status */
  @Input() showCloseButton = true;

  /** Property to hold modal icon status */
  @Input() showModalIcon = true;

  /** Property to hold modal variant type */
  @Input() variant: AlertType = AlertType.ERROR;

  /** Property to handle modal open/close value */
  public isOpen = false;

  /** Property to handle modal cancelable value */
  public isCancelable = true;

  /** Property to handle modal positive button click */
  @Input() positiveButtonClick?: () => void;

  /** Property to handle modal negative button click */
  @Input() negativeButtonClick?: () => void;

  /** Modal Subscription */
  private subscription$: any = null;

  @ViewChild('modal', { static: true }) modal: ElementRef;

  constructor (
    private modalService: ModalService,
    private dropdownService: DropdownService,
  ) {
    // Subscribe Alert Toggle From Alert Service
    this.subscription$ = this.modalService.currentConfigSubject.subscribe(
      (value) => {
        // Assign values from alert service
        this.isOpen = value.open;
        this.variant = value.variant;
        this.title = value.title;
        this.message = value.message;
        this.positiveButtonText = value.positiveButtonText;
        this.negativeButtonText = value.negativeButtonText;
        this.positiveButtonClick = value.positiveButtonClick;
        this.negativeButtonClick = value.negativeButtonClick;
        this.isCancelable = value.isCancelable;
      },
    );
  }

  /**
   * Dismiss Message Modal
   */
  dismissModal () {
    this.modalService.dismissModal();
  }

  /**
   * Negative Button Click
   */
  onPositiveButtonClick () {
    this.positiveButtonClick?.();
    this.dismissModal();
  }

  /**
   * Positive Button Click
   */
  onNegativeButtonClick () {
    this.negativeButtonClick?.();
    this.dismissModal();
  }

  /**
   * Close Modal Component
   */
  closeDropdowns (event: any): void {
    event.stopPropagation();
    event.preventDefault();
    const modalContent = this.modal.nativeElement;
    if (
      event.target &&
      modalContent.contains(event.target as Node) &&
      !event.target.classList.contains('dropdown')
    ) {
      this.dropdownService.openDropdown.next('');
    }
  }

  /**
   * On Destroy
   */
  ngOnDestroy () {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }
  }
}
