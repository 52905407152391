/* eslint-disable max-lines */
import { BehaviorSubject, Subject } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppConstants } from 'src/app/constants/AppConstants';
import { Injectable } from '@angular/core';
import { ProfileModel } from 'src/app/models/ProfileModel';
import { SideBarTabs } from '../../constants/navigation-screen';
import countries from '../../../assets/statics/countries.json';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
  theme = this.isDarkTheme ? 'dark' : 'light';

  // START - Loaders
  showLoader: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showTableLoader: BehaviorSubject<boolean> = new BehaviorSubject(false);
  inputFieldLoader: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // END - Loaders

  // User Profile Data
  profileModel: BehaviorSubject<ProfileModel> = new BehaviorSubject<any>({});

  // open | collapsed
  sideBarStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);

  activeTab: BehaviorSubject<SideBarTabs> = new BehaviorSubject(
    SideBarTabs.INVENTORY,
  );

  // Refresh route
  refreshRoute: Subject<any> = new Subject();

  constructor (private sanitizer: DomSanitizer) {
    //Empty Constructor
  }

  public emailRegex =
  // eslint-disable-next-line
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public passwordRegex =
  // eslint-disable-next-line no-useless-escape
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/;

  public urlRegex =
  // eslint-disable-next-line no-useless-escape
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gm;

  /**
     * Property to display selected country in dropdown
     */
  selectedCountry = {
    dial_code: '+1',
    flag: 'assets/statics/flags/ad.svg',
    characters: '9',
  };

  /**
     * Property to hold search text in dropdown
     */
  searchText = '';

  /**
     * Property to hold all countries
     */
  countries: any = [];

  /**
     * Property to hold searched countries
     */
  searchedCountries: any = [];

  // Start Loader
  showLoading () {
    this.showLoader.next(true);
  }

  // Stop Loader
  dismissLoading () {
    this.showLoader.next(false);
  }

  // Start/Stop Table Loader
  tableLoader (action: 'start' | 'stop') {
    this.showTableLoader.next(action === 'start');
  }

  // Show Input Field Loader
  showInputFieldLoader () {
    this.inputFieldLoader.next(true);
  }

  // Dismiss Input Field Loader
  dismissInputFieldLoader () {
    this.inputFieldLoader.next(false);
  }

  checkInput (event: any, type?: string, limit?: number): boolean {
    const isNumericKey = (keyCode) =>
      (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105);

    const isAlphabeticKey = (keyCode) =>
      (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105);

    if (type === 'number') {
      return (
        (event.keyCode >= 35 && event.keyCode <= 40) ||
                [ 46, 13, 8, 9, 27, 110, 190 ].includes(event.keyCode) ||
                (event.target.value.length !== limit && isNumericKey(event.keyCode))
      );
    } else {
      return true;
    }
  }

  /**
     * Method returns countries list from json
     * @returns countries list
     */
  async getAllCountries () {
    try {
      return await countries;
    } catch (error) {
      console.error('Error fetching static text:', error);
      throw error;
    }
  }

  // Switch Theme
  switchTheme (value: boolean) {
    this.isDarkTheme = value;
    document.body.classList.toggle('body-dark', value);
  }

  /**
     * Validates an input value to check if it's empty or not.
     * @param inputValue The input value to be validated.
     * @returns Returns true if the input value is empty, otherwise false.
     */
  public validateInput (inputValue?: string): boolean {
    let isError = false;
    // If inputValue's length is falsy (null, undefined, or zero), it's considered empty
    if (!inputValue || !inputValue.length) {
      isError = true;
    } else {
      isError = false;
    }
    return isError;
  }

  /**
     * Checks if the input value is not null, not undefined, and not an empty string.
     * @param value The input value to be checked.
     * @returns Returns true if the value is not null, not undefined, and not an empty string; otherwise, returns false.
     */
  public isNotNullOrEmpty (value?: string | undefined | null): boolean {
    return value && value !== '';
  }

  /**
     * Validates an input value to check if it's a valid email or not.
     * @param inputValue The input value to be validated.
     * @returns Returns true if the input value is valid email, otherwise false.
     */
  public isValidEmail (inputValue?: string): boolean {
    if (!inputValue || !inputValue.length) return false;
    return !inputValue.match(new RegExp(this.emailRegex));
  }

  /**
     * Validates an input value to check if it matches the specified password criteria.
     * Modify the regex pattern and criteria as needed for password validation.
     * @param inputValue The input value to be validated as a password.
     * @returns Returns true if the input value matches the password criteria, otherwise false.
     */
  public isValidPassword (inputValue?: string): boolean {
    if (!inputValue || !inputValue.length) return false;

    return !this.passwordRegex.test(inputValue);
  }

  /**
     * Validates a confirmed password to check if it matches the original password.
     * @param password The original password against which the confirmation is checked.
     * @param confirmPassword The confirmed password value to be validated.
     * @returns Returns true if the confirmed password matches the original password, otherwise false.
     */
  public isValidConfirmPassword (
    password?: string,
    confirmPassword?: string,
  ): boolean {
    // Check if input password and confirm password is not null/undefined/empty
    if (
      !password ||
            !password.length ||
            !confirmPassword ||
            !confirmPassword.length
    )
      return false;

    // Check if the confirmed password meets the password criteria using the password regex
    const confirmPasswordValid = !confirmPassword.match(
      new RegExp(this.passwordRegex),
    );

    // Check if the confirmed password matches the original password
    const passwordsMatch = password === confirmPassword;

    // Return true if both condition true
    return passwordsMatch && !confirmPasswordValid;
  }

  /**
     * Validates an input value to check if it's empty or not.
     * @param inputValue The input value to be validated.
     * @returns Returns true if the input value is empty, otherwise false.
     */
  public isValidPhoneNumber (inputValue?: string): boolean {
    let isError = false;
    // If inputValue's length is falsy (null, undefined, or zero), it's considered empty
    if (inputValue && inputValue.length > 6 && inputValue.length < 16) {
      isError = false;
    } else {
      isError = true;
    }

    return isError;
  }

  /**
     * Validates a string to check if it represents a valid hexadecimal color code.
     * @param hexColor The string to be validated as a hexadecimal color code.
     * @returns Returns true if the input string represents a valid hexadecimal color code, otherwise false.
     */
  public isValidHexColor (hexColor: string): boolean {
    // Check if the input string is empty
    if (!hexColor) {
      return true;
    }
    // Regular expression to match hexadecimal color code
    const hexColorRegex = /^#?([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/;
    return !hexColorRegex.test(hexColor);
  }

  /**
     * @param profileModel Logged In User User Data
     * */
  public setProfileData (profileModel: ProfileModel) {
    this.profileModel.next(profileModel);
  }

  /**
     * Method to format input string
     * @param input String to format
     * @returns It returns a formatted string as capitalized first letter
     */
  toTitleCase (input: string): string {
    if (!input) {
      return '';
    }
    return input
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  /**
     * Function to perform a deep copy on an object
     * @param obj - The object to be deeply copied
     * @returns A new object that is a deep copy of the input object
     */
  createDeepCopy (obj: any): any {
    if (!obj) {
      return null;
    }
    return JSON.parse(JSON.stringify(obj));
  }

  public getCookie (name: string): string {
    const cookieValue = document.cookie.match(
      `(^|;)\\s*${ name }\\s*=\\s*([^;]+)`,
    );
    return cookieValue ? cookieValue.pop() : '';
  }

  public setCookie (name: string, value: string, days: number) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + days);
    document.cookie = `${ name }=${ value }; expires=${ expirationDate.toUTCString() }; path=/`;
  }

  /**
     * Checks if the value is not null, not undefined, and not empty, and if it is a valid image URL pattern.
     * @param value The input value to be checked.
     * @returns Returns true if the value matches all conditions
     */
  public isValidImageUrl (value?: string | undefined | null): boolean {
    if (!value || value.trim() === '') {
      return false;
    }

    // Regular expression pattern for a simple image URL check
    const imageUrlPattern = /\.(jpeg|jpg|gif|png)$/i;

    // Check if the value is a valid image URL based on the regex
    return imageUrlPattern.test(value.trim());
  }

  /**
     * Validates if the input value is a correct web URL.
     * @param inputValue The input value to be validated.
     * @returns Returns true if the input value is a valid web URL, otherwise false.
     */
  public isValidWebUrl (inputValue?: string): boolean {
    let isValid = false;

    // Regular expression pattern for validating a web URL
    const webUrlPattern = /^(https?:\/\/)?((([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[^\s]*)?$/i;

    // If inputValue is non-empty and matches the URL pattern, it is considered valid
    if (inputValue && webUrlPattern.test(inputValue.trim())) {
      isValid = true;
    }

    return !isValid;
  }

  /**
     * Download CSV File
     */
  public downloadCSV (response: any, fileName?: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.setAttribute('target', '_self');
    a.href = `data:text/csv,${ response }`;
    a.download = fileName ?? 'file';
    a.click();
    window.URL.revokeObjectURL(response);
    a.remove();
  }

  /**
     * Download Sample CSV
     */
  public downloadSampleCSV () {
    const link = document.createElement('a');
    link.href =
            'https://chatahoot-media.s3.eu-west-1.amazonaws.com/assets/files/z74cF-1713339167800-sample-inventory--1-.csv';
    link.target = '_blank'; // Open the link in a new tab/window
    link.download = 'downloaded-file';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  /**
     * Extract File Name From URL
     */
  public extractFileNameFromUrl (url?: string): string {
    if (!url) return;
    const urlParts = url.split('/');
    const fileNameWithTimestamp = urlParts[urlParts.length - 1];
    const fileNameParts = fileNameWithTimestamp.split('_');
    const fileName = fileNameParts.slice(1).join('_');
    return fileName;
  }

  /**
     * Method to return string initials
     *
     * @param fullName String to get initials
     * @returns String initials
     */
  getNameInitials (fullName?: string) {
    // Check Name Validation
    if (!fullName) return;

    const nameParts = fullName.split(' ');

    let name = '';

    // Check if more than one word name
    if (nameParts.length > 1) {
      // Get First Character of First Name
      const firstInitial = nameParts[0].charAt(0);

      // Get First Character of Last Name
      const secondInitial = nameParts[nameParts.length - 1].charAt(0);

      // Return Initials
      name = `${ firstInitial }${ secondInitial }`;
      return name.toUpperCase();
    } else if (fullName.length > 1) {
      // Check if only one word name
      // Return two initials of first name
      const firstInitial = nameParts[0].charAt(0);
      const secondInitial = nameParts[0].charAt(1) || '';
      name = `${ firstInitial }${ secondInitial }`;
      name = `${ firstInitial }${ secondInitial }`;
      return name.toUpperCase();
    } else {
      // Return the single character if the name is only one character long
      return fullName.toUpperCase();
    }
  }

  /**
     * Formats the given tab name string.
     * @param value The string representing the tab name to be formatted.
     * @returns The formatted tab name.
     */
  formatTabName (value: string): string {
    const parts: string[] = value.split('_');
    const output: string = parts.slice(1).map((part) => {
      return part.replace(/\b\w/g, (l) => l.toUpperCase());
    }).join(' ');
    return output.replace(/And/g, '&');
  }

  /**
     * Request to Clear User Data
     *
     * @return Promise with Result
     * */
  clearUserData (): void {
    localStorage.removeItem(AppConstants.LocalStorageKeys.AUTH_TOKEN);
    localStorage.removeItem(AppConstants.LocalStorageKeys.USER_TYPE);
    localStorage.removeItem(AppConstants.LocalStorageKeys.VERIFY_AUTH_TOKEN);
    localStorage.removeItem(AppConstants.LocalStorageKeys.CATEGORY_IDS);
    localStorage.removeItem(AppConstants.LocalStorageKeys.VEC_LOGIC_EMBEDDING_IDS);
    localStorage.removeItem(AppConstants.LocalStorageKeys.CATEGORY_NAME);

    localStorage.removeItem('impersonate');
    window.location.href = '/authentication';
  }


  /**
     * Checks if the resolution of the image file is within specified limits.
     * @param file The File object representing the image file to check.
     * @returns true if the image resolution is within the specified limits, false otherwise.
     */
  isValidResolution (file: File): boolean {
    const reader: FileReader = new FileReader();
    reader.onload = (e: any): void => {
      const image: HTMLImageElement = new Image();
      image.src = e.target.result;

      image.onload = () => {
        const originalWidth: number = image.width;
        const originalHeight: number = image.height;

        // Check if the image resolution is under 500 pixels
        if (originalWidth <= 500 && originalHeight <= 500) {
          return true;
        }
      };
    };
    reader.readAsDataURL(file);
    return false;
  }

  /**
     * Resizes an image file to a preferred resolution and returns the resized File object.
     * @param file The File object representing the image file to resize.
     * @param resolution The preferred resolution to resize the image (default is 500 pixels).
     * @returns The resized File object with the preferred resolution.
     */
  getPreferredResolutionImage (file: File, resolution: number = 500): Promise<File> {
    return new Promise((resolve, reject) => {
      const reader: FileReader = new FileReader();

      reader.onload = (e: any): void => {
        const image: HTMLImageElement = new Image();
        image.src = e.target.result;

        image.onload = () => {
          // Resize image to desired resolution pixels
          const canvas: HTMLCanvasElement = document.createElement('canvas');
          const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;

          // Set canvas dimensions to defined resolution
          canvas.width = resolution;
          canvas.height = resolution;

          // Resize image
          ctx.drawImage(image, 0, 0, resolution, resolution);

          // Determine the file type and set the MIME type accordingly
          let mimeType = 'image/png';
          if (file.type === 'image/jpeg' || file.type === 'image/jpg') {
            mimeType = 'image/jpeg';
          }

          // Convert canvas to Blob
          canvas.toBlob((blob: Blob) => {
            if (blob) {
              // Convert Blob to File
              const resizedFile: File = new File([ blob ], file.name, {
                type: mimeType,
                lastModified: Date.now(),
              });
              resolve(resizedFile);
            } else {
              reject(new Error('Failed to resize image'));
            }
          }, mimeType);
        };
      };

      reader.readAsDataURL(file);
    });
  }


  /**
     * Converts a given string to Camel Case format.
     * @param value The input string to be converted.
     * @returns The Camel Case formatted string or "NA" if the input is invalid.
     */
  getCamelCaseValue (value: string): string {
    if (value && typeof value === 'string') {
      if (value.includes('_')) {
        const replace: string = value.replace(/_/g, ' ');
        return replace
          .split(' ')
          .map((w: string) => w[0].toUpperCase() + w.substring(1).toLowerCase())
          .join(' ');
      } else {
        const camelCaseValue = value
          .split(' ')
          .map((w: string) => w[0].toUpperCase() + w.substring(1).toLowerCase())
          .join(' ');
        return camelCaseValue.replace(/-/g, ' ');
      }
    } else {
      return 'NA';
    }
  }

  /**
     * Extracts plain text from a given HTML string.
     * @param value The input HTML string to be converted.
     * @returns The plain text extracted from the HTML string.
     */
  getTextFromHtml (value: string): string {
    const div = document.createElement('div');
    div.innerHTML = value;
    return div.innerText;
  }

  /**
     * Sanitizes a URL for safe use in Angular templates.
     * @param url The URL to be sanitized.
     * @returns The sanitized URL as a SafeResourceUrl.
     */
  getSanitizedUrl (url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  /**
     * Combines date and time strings into an ISO 8601 format string.
     * Converts the provided date and 12-hour time into a single ISO string.
     */
  combineDateTimeToISOString (dateString: string, timeString: string): string {
    // Format: "Aug 20, 2024" and "04:10 AM"
    // Convert date string to Date object
    const date: Date = new Date(dateString);

    // Parse time string to hours and minutes
    const [ time, modifier ] = timeString.split(' ');
    const [ hours, minutes ] = time.split(':').map(Number);

    // Convert 12-hour format to 24-hour format
    let hours24 = hours;
    if (modifier === 'PM' && hours !== 12) {
      hours24 += 12;
    } else if (modifier === 'AM' && hours === 12) {
      hours24 = 0;
    }

    // Set the time to the date object
    date.setHours(hours24, minutes, 0, 0); // Set hours, minutes, seconds, and milliseconds

    // Convert to ISO string
    return date.toISOString();
  }

  /**
     * Function to format time in AM/PM format
     * @param date input date
     */
  formatTime (date: Date): string {
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    return `${ hours }:${ minutes } ${ ampm }`;
  }

  /**
     * Validates an input value to check if it's empty or not.
     * @param inputValue The input value to be validated.
     * @returns Returns true if the input value is empty, otherwise false.
     */
  public validateCurrentTime (inputValue?: string): boolean {
    let isError = false;
    // If inputValue's length is falsy (null, undefined, or zero), it's considered empty
    if (!inputValue || !inputValue.length) {
      isError = true;
    } else {
      isError = false;
    }
    return isError;
  }

  /**
     * Converts html to plain text
     * @param html string
     */
  convertHtmlToPlainText (html: string): string {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;
    return tempElement.textContent?.trim() || tempElement.innerText?.trim() || '';
  }

  /**
     * Generates a random background color based on the specified brightness type.
     * Supports dark, light, and random color generation.
     */
  getRandomBackgroundColor (isLightColor: boolean = false, isDarkColor: boolean = false, isRandomColor: boolean = true): string {
    function generateColor (isLight: boolean): string {
      const min = isLight ? 200 : 0; // Higher minimum value for lighter colors
      const r = Math.floor(Math.random() * (256 - min) + min);
      const g = Math.floor(Math.random() * (256 - min) + min);
      const b = Math.floor(Math.random() * (256 - min) + min);
      return `#${ r.toString(16).padStart(2, '0') }${ g.toString(16).padStart(2, '0') }${ b.toString(16).padStart(2, '0') }`;
    }

    function calculateBrightness (color: string): number {
      const r = parseInt(color.slice(1, 3), 16);
      const g = parseInt(color.slice(3, 5), 16);
      const b = parseInt(color.slice(5, 7), 16);
      return (r * 299 + g * 587 + b * 114) / 1000;
    }

    let color: string;
    let brightness: number;

    do {
      color = generateColor(isLightColor);
      brightness = calculateBrightness(color);

      if (isDarkColor && brightness < 100) {
        break; // Return if dark color condition is met
      } else if (isLightColor && brightness > 200) {
        break; // Return if light color condition is met
      } else if (isRandomColor && brightness >= 100 && brightness <= 200) {
        break; // Return if random color condition is met
      }
    } while (true);

    return color;
  }

}
