/**
 * Keys for Navigation Component
 */
export enum NavigationScreenRoute {
    /** Authentication Module Screens */
    LOGIN = 'login',
    FORGOT_PASSWORD = 'forgot-password',
    EMAIL_VERIFICATION = 'email-verification',
    SET_NEW_PASSWORD = 'set-new-password',
    DELETE_ACCOUNT = 'delete-account',

    /** Dashboard Module Screen */
    /**
     * Tab Screens
     * Remember: Screen Name should be same as Tab Name
     * */
    INVENTORY = '/admin/inventory',
    ANALYTICS = '/admin/analytics',
    CUSTOMER_CHAT = '/admin/customer-chat',
    USERS = '/admin/users',
    ACCOUNT = '/admin/account',
    LOGOUT = '/admin/logout',
    CATEGORY_INVENTORY_TABS = '/admin/category-inventory-tabs',
    SHOP_PRIORITY = '/admin/shop-priority',
    MANAGE_CATEGORIES = '/admin/manage-categories',
    SUGGESTION_PROMPTS = '/admin/suggestion-prompts',
    BROADCAST = '/admin/broadcast',
    CATEGORY_ACTIONS = '/admin/category-actions',
    ASK_ME_ANYTHING = '/admin/ask-me-anything',
    CMS = '/admin/cms',
    MODERATE_REQUESTS = '/admin/moderate-requests',

    /** Account Module Screens */
    EDIT_PROFILE = '/admin/edit-profile',
    CHANGE_PASSWORD = '/admin/change-password',
}

export enum AccountScreenRoute {
    /** Account Module Screens */
    EDIT_PROFILE = 'edit-profile',
    CHANGE_PASSWORD = 'change-password',
}

/**
 * Keys for Sidebar Tabs Navigation Component
 */
export enum SideBarTabs {
    ANALYTICS = 'analytics',
    INVENTORY = 'inventory',
    USERS = 'users',
    CUSTOMER_CHAT = 'customer-chat',
    ACCOUNT = 'account',
    LOGOUT = 'logout',
    CATEGORY_INVENTORY_TABS = 'category-inventory-tabs',
    MANAGE_CATEGORIES = 'manage-categories',
    SUGGESTION_PROMPTS = 'suggestion-prompts',
    ADDITIONAL_PROMPTS = 'additional-prompts',
    BROADCAST = 'broadcast',
    ASK_ME_ANYTHING = 'ask-me-anything',
    CMS = 'cms',
    MODERATE_REQUESTS = 'moderate-requests',
}

/**
 * Keys for Sub Navigation Screen
 */
export enum SubNavigationScreen {
    SHOP_PRIORITY = 'shop-priority',
    SUGGESTION_PROMPTS = 'suggestion-prompts',
    BROADCAST = 'broadcast',
    PARTNERS = 'partners',
    CUSTOMERS = 'customers',
    OPEN_MODERATE = 'open-moderate-requests',
    CLOSED_MODERATE = 'closed-moderate-requests',
    MODERATE_CONFIGURATION = 'moderate-configuration',
    CATEGORY_ACTIONS = 'category-actions',
}
